import React from "react";
import ScrollCategoryTab from "./ScrollCategoryTab";

function ScrollView({ categories }) {
  return (
    <>
      {categories?.map((category, i) => (
        <ScrollCategoryTab category={category} key={i}></ScrollCategoryTab>
      ))}
    </>
  );
}

export default ScrollView;
