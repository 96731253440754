import React, { useContext, useState } from 'react'
import { AreaContext, CartContext, LanguageContext, VendorContext, VendorSlugContext } from '../../../App'
import { API_URL } from '../../../services/constants'
import axios from 'axios'
import CrossIcon from '../../../SVGs/CrossIcon'

const NewPromocode = ({ setPromocode, promocode, setSuccessPromocode, setApply, apply, afterPromocode, deliveryCharge }) => {
    const { language } = useContext(LanguageContext)
    const details = useContext(VendorContext)
    const vendorSlug = useContext(VendorSlugContext)
    const { setCart } = useContext(CartContext)
    const { areaDetails } = useContext(AreaContext)
    const [apiCalled, setApiCalled] = useState(false)

    const onApplyClick = () => {
        if (!apiCalled) {
            setApiCalled(true)
            if (promocode != "") {
                axios
                    .post(
                        `${API_URL}/apply-promocode`,
                        JSON.stringify({
                            token: process.env.REACT_APP_TOKEN,
                            user_string: localStorage.getItem("userID"),
                            vendor_slug: vendorSlug,
                            vendor_id: details?.vendor?.vendors_id,
                            area_id: areaDetails?.area_id,
                            promocode: promocode,
                            deliveryCharge: deliveryCharge
                        })
                    )
                    .then((res) => {
                        if (res.data.status) {
                            setApply((apply) => res.data.status);
                            setSuccessPromocode((pro) => res.data.result.promo_code);
                            setCart((cart) => res.data.result);
                            setApiCalled(false)
                        } else {
                            setApply((apply) => res.data.status);
                            setApiCalled(false)
                        }
                    })
                    .catch((e) => console.log(e));
            }
        }
    };
    return (
        <div className='promocode-mainDiv'>
            <div className='delivery-addres-heading-checkout'>
                {language === "ltr" ? "Promotion Code" : "الرمز الترويجي"}
            </div>
            <div className={`promocode-input-div ${details?.vendor?.home_page_type === "18" && "fashion-theme"}`}>

                <div className="promocode-input-div1">
                    <input
                        type="text"
                        className={`form-control ${details?.vendor?.home_page_type === "18" && "fashion-theme-border"}`}
                        id="name"
                        name="first_name"
                        required="true"

                        value={promocode}
                        onChange={(e) => setPromocode(e.target.value)}
                    ></input>
                    {promocode && <div className='promocode-cross-button' onClick={(e) => {
                        e.preventDefault()
                        setPromocode("")
                        setApply("")
                    }}>
                        <CrossIcon size={"13"} />
                    </div>}
                </div>
                <div className='apply-button' onClick={() => onApplyClick()}>
                    {language == "ltr" ? "Apply" : "تفعيل"}
                </div>
            </div>
            {apply !== "" && (
                <p
                    className="error-text"
                    style={{
                        marginTop: "10px",
                        color: apply ? "#02b201" : "red",
                    }}
                >
                    {apply ? (
                        <>
                            <i
                                style={{ fontSize: 15 }}
                                className="fa fa-check-circle-o"
                            ></i>
                            &nbsp;
                            {language == "ltr"
                                ? "Promocode is applied"
                                : "تم تفعيل الرمز الترويجي"}
                        </>
                    ) : language == "ltr" ? (
                        "Invalid Promo code passed"
                    ) : (
                        "الرمز الترويجي غير صحيح"
                    )}
                </p>
            )}
        </div>
    )
}

export default NewPromocode