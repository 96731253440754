import React, { useContext } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import {
  AreaContext,
  ContactContext,
  LanguageContext,
  VendorContext,
} from "../../../App";
import { getAddressType } from "../../../commonFunction/commonFunction";
import moment from "moment";
import ClockIcon from "../../../SVGs/ClockIcon";

const NewDeliveryDetails = ({ addressDetails, companyData }) => {
  const { areaDetails } = useContext(AreaContext);
  const { language } = useContext(LanguageContext);
  const { contactDetails } = useContext(ContactContext);
  const details = useContext(VendorContext);

  const houseLabel = () => {
    switch (addressDetails.addressType) {
      case "1":
        return language == "ltr" ? "House No." : "عمارة";

      case "2":
      case "3":
        return language == "ltr" ? "Building no." : "عمارة";

      case "4":
        return language == "ltr" ? "School Name" : "اسم المدرسة";

      case "5":
        return language == "ltr" ? "Mosque Name" : "اسم المسجد";

      case "6":
        return language == "ltr" ? "Government Entity" : "مبنى حكومة";
      default:
        return "";
    }
  };
  const flatLabel = () => {
    switch (addressDetails.addressType) {
      case "2":
        return language == "ltr" ? "Flat no." : "رقم الشقة";
      case "3":
        return language == "ltr" ? "Office no." : "رقم المكتب";

      default:
        return "";
    }
  };
  const getGovernarate = () => {
    let newData;
    areaDetails?.data?.governarate?.map((governrnate, i) =>
      governrnate?.area?.map((ele, j) => {
        if (ele.area_id == areaDetails?.area_id) {
          newData = governrnate;
        }
      })
    );
    return newData;
  };
  return (
    <div /* style={{ paddingTop: "10px" }} */>
      <div className="delivery-addres-heading-checkout">
        {areaDetails?.type == "delivery"
          ? language === "ltr"
            ? "Delivery Address"
            : "عنوان التسليم"
          : language === "ltr"
            ? "Pick Up Details"
            : "التقط التفاصيل"}
      </div>
      <Link
        to={
          areaDetails.type === "delivery"
            ? "/delivery-address"
            : "/area"
        }
        className="delivery-address-details-mainDIv"
      >
        <div className="delivery-address-details-firstDiv">
          {areaDetails?.area && (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <div
                style={{
                  width: "20px",
                  height: "14px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {getAddressType(
                  addressDetails.addressType,
                  details.vendor.vendor_color,
                  "19"
                )}
              </div>
              <div>
                {language === "ltr"
                  ? getGovernarate().governarate_name
                  : getGovernarate().governarate_name_ar}
              </div>
            </div>
          )}

          <div className="delivery-alignment">
            {areaDetails.type === "delivery" ? (
              <div>
                {language === "ltr" ? areaDetails?.area : areaDetails?.ar_area}
              </div>
            ) : (
              <div>
                {language === "ltr"
                  ? areaDetails?.branch
                  : areaDetails?.ar_branch}
              </div>
            )}
          </div>
          {areaDetails.type === "delivery" ? (
            <>
              <div className="delivery-alignment">
                {language == "ltr" ? "Block" : "قطعة "} {addressDetails?.block}
                {", "}
                {language == "ltr" ? "Street" : "شارع "}{" "}
                {addressDetails?.street}
                {addressDetails?.avenue ? (
                  <>
                    {", "}
                    {language == "ltr" ? "Ave." : "شارع"}{" "}
                    {addressDetails?.avenue}
                  </>
                ) : null}
                {", "}
                {houseLabel()} {addressDetails?.house}
              </div>
              {addressDetails?.floor || addressDetails?.flat ? (
                <div className="delivery-alignment">
                  {addressDetails?.floor ? (
                    <>
                      {language == "ltr" ? "Floor no." : "رقم الدور"}{" "}
                      {addressDetails.floor}
                    </>
                  ) : null}
                  {addressDetails?.flat ? (
                    <>
                      {", "}
                      {flatLabel()} {addressDetails?.flat}
                    </>
                  ) : null}
                </div>
              ) : null}
              {addressDetails?.special_directions ? (
                <div className="delivery-alignment">
                  {addressDetails.special_directions}
                </div>
              ) : null}
            </>
          ) : (
            contactDetails.model || contactDetails.color || contactDetails.license ? <div className="delivery-alignment">
              {contactDetails.model && (
                <>
                  {language == "ltr" ? "Model" : "نوع السيارة"}{" "}
                  {contactDetails.model}
                </>
              )}
              {contactDetails.color && (
                <>
                  {", "}
                  {language == "ltr" ? "Color" : "اللون"} {contactDetails.color}
                </>
              )}
              {contactDetails.license && (
                <>
                  {", "}
                  {language == "ltr"
                    ? "License Plate "
                    : "رقم لوحة السيارة"}{" "}
                  {contactDetails.license}
                </>
              )}
            </div> : null
          )}
          {!companyData ? (
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <div
                style={{
                  width: "20px",
                  height: "14px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ClockIcon color={details.vendor.vendor_color} />
              </div>
              <div>
                {areaDetails.type === "delivery" ? (
                  <span>
                    {areaDetails?.now == 1
                      ? language === "ltr"
                        ? `${!areaDetails?.customDelivery
                          ? "Delivery Within"
                          : ""
                        } ${areaDetails?.deliveryTiming}`
                        : `${!areaDetails?.customDelivery
                          ? "التوصيل سيكون خلال"
                          : ""
                        } ${areaDetails?.ar_deliveryTiming}`
                      : moment(areaDetails?.laterDeliveryTiming)
                        .locale("en")
                        .format("DD") +
                      " " +
                      moment(areaDetails?.laterDeliveryTiming)
                        .locale(language == "ltr" ? "en" : "ar-sa")
                        .format("MMMM") +
                      moment(areaDetails?.laterDeliveryTiming)
                        .locale("en")
                        .format(", yyyy hh:mm ") +
                      moment(areaDetails?.laterDeliveryTiming)
                        .locale(language == "ltr" ? "en" : "ar-sa")
                        .format("A")}
                  </span>
                ) : (
                  <span>
                    {areaDetails?.now == 1
                      ? language === "ltr"
                        ? `${areaDetails?.deliveryTiming}`
                        : `${areaDetails?.ar_deliveryTiming}`
                      : moment(areaDetails?.laterDeliveryTiming)
                        .locale("en")
                        .format("DD") +
                      " " +
                      moment(areaDetails?.laterDeliveryTiming)
                        .locale(language == "ltr" ? "en" : "ar-sa")
                        .format("MMMM") +
                      moment(areaDetails?.laterDeliveryTiming)
                        .locale("en")
                        .format(", yyyy hh:mm ") +
                      moment(areaDetails?.laterDeliveryTiming)
                        .locale(language == "ltr" ? "en" : "ar-sa")
                        .format("A")}
                  </span>
                )}
              </div>
            </div>
          ) : null}
        </div>
        <div className="delivery-address-details-secondDiv">
          <i className="fa fa-angle-right right-arrow"></i>
        </div>
      </Link>
    </div>
  );
};

export default NewDeliveryDetails;
