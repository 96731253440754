import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { ClipLoader } from "react-spinners";
import { SearchContext, VendorContext } from "../../App";
import SearchNone from "../HomePage/components/SearchNone";
import ProductsList from "../Products/components/ProductsList";
import SearchContainer from "./components/SearchContainer";
import SearchText from "./components/SearchText";
import SnapPixel from "react-snapchat-pixel";

function SearchPage() {
  const [searchItems, setSearchItems] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const { search } = useContext(SearchContext);
  const [searchText, setSearchText] = useState("");
  const details = useContext(VendorContext);
  const [page, setPage] = useState(0);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [hasMore, setHasMore] = useState(0);

  const observer = useRef();

  useEffect(() => {
    if (
      details?.vendor?.snap_pixel_code &&
      details?.vendor?.snap_pixel_code != ""
    )
      SnapPixel.pageView();
  }, []);

  const lastBookElementRef = useCallback(
    (node) => {
      if (isPageLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isPageLoading, hasMore]
  );
  return (
    <>
      <SearchContainer
        searchItems={searchItems}
        setSearchItems={setSearchItems}
        searchText={searchText}
        setSearchText={setSearchText}
        setSearchLoading={setSearchLoading}
        page={page}
        setIsPageLoading={setIsPageLoading}
        setHasMore={setHasMore}
        hasMore={hasMore}
        setPage={setPage}
      ></SearchContainer>
      <SearchText></SearchText>
      {search != "" ? (
        searchItems?.length != 0 || searchLoading ? (
          <>
            <ProductsList
              loading={searchLoading}
              lastBookElementRef={lastBookElementRef}
              products={searchItems}
            ></ProductsList>
            {isPageLoading && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "10px 0",
                }}
              >
                <ClipLoader
                  size={40}
                  color={details?.vendor?.vendor_color}
                ></ClipLoader>
              </div>
            )}
          </>
        ) : (
          <SearchNone searchText={searchText}></SearchNone>
        )
      ) : null}
    </>
  );
}

export default SearchPage;
