import React, { useContext } from 'react'
import CommonSectionHeader from './CommonSectionHeader';
import { LanguageContext } from '../../../App';

const PickupContainer = ({ contactDetails, setContactDetails, pickupError }) => {
    const { language } = useContext(LanguageContext)
    return (
        <>
            <CommonSectionHeader englishHeader="Pickup car information" arabicHeader="معلومات السيارة للاستلام " />
            <CommonSectionHeader englishHeader="Help us quickly identify your vehicle" arabicHeader="ساعدنا في التعرف على سيارتك بسرعة." />
            <div className='contact-details-form-maindiv'>
                <div className="contact-details-form-div" style={{ display: "flex", alignItems: "flex-end" }} >
                    <div style={{ width: "100%" }}>
                        <div className='contact-form-container'>
                            <input
                                type="text"
                                className="contact-details-form-control"
                                placeholder=""
                                id="car_model"
                                name="car_model"
                                required="true"
                                value={contactDetails.model}
                                autoComplete
                                onChange={(e) => {
                                    setContactDetails({
                                        ...contactDetails,
                                        model: e.target.value,
                                    });
                                }}
                            ></input>
                            <label for="car_model" className="contact-details-label-name contact-details-label-name1">
                                {language == "ltr" ? "Model" : "نوع السيارة"}
                            </label>
                        </div>
                        {pickupError.modelError && (
                            <label className="error-text">
                                {language == "ltr"
                                    ? "This field is compulsory"
                                    : "مطلوب ملء هذا الحقل"}
                            </label>
                        )}
                    </div>
                </div>
                <div className="contact-details-form-div" style={{ display: "flex", alignItems: "flex-end" }} >
                    <div style={{ width: "100%" }}>
                        <div className='contact-form-container'>
                            <input
                                type="text"
                                className="contact-details-form-control"
                                placeholder=""
                                id="car_color"
                                name="car_color"
                                required="true"
                                value={contactDetails.color}
                                autoComplete
                                onChange={(e) => {
                                    setContactDetails({
                                        ...contactDetails,
                                        color: e.target.value,
                                    });
                                }}
                            ></input>
                            <label for="car_color" className="contact-details-label-name contact-details-label-name1">
                                {language == "ltr" ? "Color" : "اللون"}
                            </label>
                        </div>
                        {pickupError.colorError && (
                            <label className="error-text">
                                {language == "ltr"
                                    ? "This field is compulsory"
                                    : "مطلوب ملء هذا الحقل"}
                            </label>
                        )}
                    </div>
                </div>
                <div className="contact-details-form-div" style={{ display: "flex", alignItems: "flex-end" }} >
                    <div style={{ width: "100%" }}>
                        <div className='contact-form-container'>
                            <input
                                type="text"
                                className="contact-details-form-control"
                                placeholder=""
                                id="car_plate"
                                name="plate"
                                required="true"
                                value={contactDetails.license}
                                autoComplete
                                onChange={(e) => {
                                    setContactDetails({
                                        ...contactDetails,
                                        license: e.target.value,
                                    });
                                }}
                            ></input>
                            <label for="car_plate" className="contact-details-label-name contact-details-label-name1">
                                {language == "ltr"
                                    ? "License Plate (Optional)"
                                    : "رقم لوحة السيارة (اختياري)"}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PickupContainer