import moment from "moment";
import React, { useContext } from "react";
import { useState } from "react";
import ReactOwlCarousel from "react-owl-carousel";

import { BookingSetContext, VendorContext } from "../../App";
import BookingDetails from "./components/BookingDetails";
import BookingPlace from "./components/BookingPlace";
import BookingPlaceSearch from "./components/BookingPlaceSearch";
import BookingSearch from "./components/BookingSearch";
import BookingVendor from "./components/BookingVendor";
import BranchBooking from "./components/BranchBooking";
import NonAttendeeBooking from "./components/NonAttendeeBooking";
import ReserverDetails from "./components/ReserverDetails";
import SubmitBooking from "./components/SubmitBooking";
import TempQuantityTheme from "./components/TempQuantityTheme";

function BookingPage({ stepper, setStepper }) {
  const details = useContext(VendorContext);
  const [openCal, setOpenCal] = useState(true);
  const [tabOpen, setTabOpen] = useState(1);
  const { bookingSets } = useContext(BookingSetContext);

  const [bookingDetails, setBookingDetails] = useState({
    time: [],
    attendee: 1,
    branch: "",
    date: moment()?.locale("en"),
    place: 0,
  });

  const [contactDetails, setContactDetails] = useState({
    name: "",
    phoneCode: "KW",
    phone: "",
    email: "",
  });

  return (
    <>
      {stepper === 0 && (
        <>
          {!bookingSets?.placeClicked ? (
            <>
              {details?.vendor?.home_page_type == 1 ? (
                <BookingDetails
                  stepper={stepper}
                  openCal={openCal}
                  setOpenCal={setOpenCal}
                  tabOpen={tabOpen}
                  setTabOpen={setTabOpen}
                  setStepper={setStepper}
                  bookingDetails={bookingDetails}
                  setBookingDetails={setBookingDetails}
                ></BookingDetails>
              ) : null}
              {details?.vendor?.home_page_type == 2 ? (
                <BookingSearch
                  stepper={stepper}
                  openCal={openCal}
                  setOpenCal={setOpenCal}
                  tabOpen={tabOpen}
                  setTabOpen={setTabOpen}
                  setStepper={setStepper}
                  bookingDetails={bookingDetails}
                  setBookingDetails={setBookingDetails}
                ></BookingSearch>
              ) : null}
              {details?.vendor?.home_page_type == 3 ? (
                <BookingPlaceSearch
                  stepper={stepper}
                  openCal={openCal}
                  setOpenCal={setOpenCal}
                  tabOpen={tabOpen}
                  setTabOpen={setTabOpen}
                  setStepper={setStepper}
                  bookingDetails={bookingDetails}
                  setBookingDetails={setBookingDetails}
                ></BookingPlaceSearch>
              ) : null}
              {details?.vendor?.home_page_type == 4 ? (
                details.vendor.booking_vendor_id !== "24" ? (
                  <BookingVendor
                    stepper={stepper}
                    openCal={openCal}
                    setOpenCal={setOpenCal}
                    tabOpen={tabOpen}
                    setTabOpen={setTabOpen}
                    setStepper={setStepper}
                    bookingDetails={bookingDetails}
                    setBookingDetails={setBookingDetails}
                  ></BookingVendor>
                ) : (
                  <TempQuantityTheme
                    stepper={stepper}
                    openCal={openCal}
                    setOpenCal={setOpenCal}
                    tabOpen={tabOpen}
                    setTabOpen={setTabOpen}
                    setStepper={setStepper}
                    bookingDetails={bookingDetails}
                    setBookingDetails={setBookingDetails}
                  ></TempQuantityTheme>
                )
              ) : null}
              {details?.vendor?.home_page_type == 5 ? (
                <NonAttendeeBooking
                  stepper={stepper}
                  setStepper={setStepper}
                  bookingDetails={bookingDetails}
                  setBookingDetails={setBookingDetails}
                ></NonAttendeeBooking>
              ) : null}
              {details?.vendor?.home_page_type == 6 ? (
                <BranchBooking
                  stepper={stepper}
                  setStepper={setStepper}
                  bookingDetails={bookingDetails}
                  setBookingDetails={setBookingDetails}
                ></BranchBooking>
              ) : null}
            </>
          ) : (
            <BookingPlace
              setStepper={setStepper}
              booking={bookingSets?.selectedPlace}
              bookingDetails={bookingDetails}
              setBookingDetails={setBookingDetails}
              setTabOpen={setTabOpen}
            ></BookingPlace>
          )}
        </>
      )}
      {stepper === 1 && (
        <ReserverDetails
          stepper={stepper}
          setStepper={setStepper}
          contactDetails={contactDetails}
          setContactDetails={setContactDetails}
          bookingDetails={bookingDetails}
        ></ReserverDetails>
      )}
      {stepper === 2 && (
        <SubmitBooking
          stepper={stepper}
          setStepper={setStepper}
          bookingDetails={bookingDetails}
          setBookingDetails={setBookingDetails}
          contactDetails={contactDetails}
          setTabOpen={setTabOpen}
          setOpenCal={setOpenCal}
        ></SubmitBooking>
      )}
    </>
  );
}

export default BookingPage;
