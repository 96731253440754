import React, { useContext, useEffect, useState } from 'react'
import { ContactContext, LanguageContext, VendorContext } from '../../../App';
import { Link, useHistory } from 'react-router-dom';
import NewAddressFormField from './NewAddressFormField';
import HomeIcon from '../../../SVGs/addressIcons/HomeIcon';
import ApparentIcon from '../../../SVGs/addressIcons/ApparentIcon';
import OfficeIcons from '../../../SVGs/addressIcons/OfficeIcons';
import MosqueIcon from '../../../SVGs/addressIcons/MosqueIcon';
import SchoolIcon from '../../../SVGs/addressIcons/SchoolIcon';
import GovernmentIcon from '../../../SVGs/addressIcons/GovernmentIcon';

const NewAddressForm = ({ areaDetails, errorState, houseValidation, streetValidation, blockValidation, handleMapChanges }) => {
    const { language } = useContext(LanguageContext)
    const details = useContext(VendorContext)
    const { addressDetails, setAddressDetails } = useContext(ContactContext)
    const history = useHistory()

    return (
        <div style={{ paddingBottom: "50px" }}>
            <div className="delivery-address-address-select" >
                <div className="delivery-address-type-select nav nav-tabs" id="forClickOnly">
                    <div
                        className={`delivery-address-type ${addressDetails.addressType === "1" ? "deliver-type-active" : ""} ${details?.vendor?.home_page_type === "18" && "fashion-theme-border"}`}
                        onClick={(e) => {
                            e.preventDefault();
                            setAddressDetails({ ...addressDetails, addressType: "1" })
                        }}
                    >
                        <div className='Address-icon-names'>
                            <div className="navlink-icon">
                                <HomeIcon color={addressDetails.addressType === "1" ? details?.vendor?.vendor_color : "#949494"} />
                            </div>
                            <div className={addressDetails.addressType === "1" ? "navlink-text-active delivery-type-text" : "navlink-text delivery-type-text"}>
                                {language == "ltr" ? "House" : "منزل"}
                            </div>
                        </div>
                    </div>

                    <div
                        className={`delivery-address-type ${addressDetails.addressType === "2" ? "deliver-type-active" : ""} ${details?.vendor?.home_page_type === "18" && "fashion-theme-border"}`}
                        onClick={(e) => {
                            e.preventDefault();
                            setAddressDetails({ ...addressDetails, addressType: "2" })
                        }}
                    >
                        <div className='Address-icon-names'>
                            <div className="navlink-icon">
                                <ApparentIcon color={addressDetails.addressType === "2" ? details?.vendor?.vendor_color : "#949494"} />
                            </div>
                            <div className={addressDetails.addressType === "2" ? "navlink-text-active delivery-type-text" : "navlink-text delivery-type-text"}>
                                {language == "ltr" ? "Apartment" : "شقة"}
                            </div>
                        </div>
                    </div>

                    <div
                        className={`delivery-address-type ${addressDetails.addressType === "3" ? "deliver-type-active" : ""} ${details?.vendor?.home_page_type === "18" && "fashion-theme-border"}`}
                        onClick={(e) => {
                            e.preventDefault();
                            setAddressDetails({ ...addressDetails, addressType: "3" })
                        }}
                    >
                        <div className='Address-icon-names'>
                            <div className="navlink-icon">
                                <OfficeIcons color={addressDetails.addressType === "3" ? details?.vendor?.vendor_color : "#949494"} />
                                {/* <i className='fa fa-briefcase' style={{ fontSize: "30px", padding: "8px", color: addressDetails.addressType === "3" ? details?.vendor?.vendor_color : "#949494" }}></i> */}
                            </div>
                            <div className={addressDetails.addressType === "3" ? "navlink-text-active delivery-type-text" : "navlink-text delivery-type-text"}>
                                {language == "ltr" ? "Office" : "مكتب"}
                            </div>
                        </div>
                    </div>
                    {details?.vendor?.enable_address_types?.includes("4") ? (

                        <div
                            className={`delivery-address-type ${addressDetails.addressType === "4" ? "deliver-type-active" : ""
                                } ${details?.vendor?.home_page_type === "18" && "fashion-theme-border"}`}
                            onClick={(e) => {
                                e.preventDefault();
                                setAddressDetails({ ...addressDetails, addressType: "4" })
                            }}
                        >
                            <div className='Address-icon-names'>
                                <div className="navlink-icon">
                                    <SchoolIcon color={addressDetails.addressType === "4" ? details?.vendor?.vendor_color : "#949494"} />
                                </div>
                                <div className={addressDetails.addressType === "4" ? "navlink-text-active delivery-type-text" : "navlink-text delivery-type-text"}>
                                    {language == "ltr" ? "School" : "مدرسة"}
                                </div>
                            </div>
                        </div>
                    ) : null}
                    {details?.vendor?.enable_address_types?.includes("5") ? (

                        <div
                            className={`delivery-address-type ${addressDetails.addressType === "5" ? "deliver-type-active" : ""
                                } ${details?.vendor?.home_page_type === "18" && "fashion-theme-border"}`}
                            onClick={(e) => {
                                e.preventDefault();
                                setAddressDetails({ ...addressDetails, addressType: "5" })
                            }}
                        >
                            <div className='Address-icon-names'>
                                <div className="navlink-icon">
                                    <MosqueIcon color={addressDetails.addressType === "5" ? details?.vendor?.vendor_color : "#949494"} />
                                </div>
                                <div className={addressDetails.addressType === "5" ? "navlink-text-active delivery-type-text" : "navlink-text delivery-type-text"}>
                                    {language == "ltr" ? "Mosque" : "مسجد"}
                                </div>
                            </div>
                        </div>
                    ) : null
                    }
                    {
                        details?.vendor?.enable_address_types?.includes("6") ? (

                            <div
                                className={`delivery-address-type ${addressDetails.addressType === "6" ? "deliver-type-active" : ""
                                    } ${details?.vendor?.home_page_type === "18" && "fashion-theme-border"}`}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setAddressDetails({ ...addressDetails, addressType: "6" })
                                }}
                            >
                                <div className='Address-icon-names'>
                                    <div className="navlink-icon">
                                        <GovernmentIcon color={addressDetails.addressType === "6" ? details?.vendor?.vendor_color : "#949494"} />
                                    </div>
                                    <div className={addressDetails.addressType === "6" ? "navlink-text-active delivery-type-text" : "navlink-text delivery-type-text"}>
                                        {language == "ltr" ? "Government" : "مبنى حكومة"}
                                    </div>
                                </div>
                            </div>
                        ) : null
                    }
                </div >

            </div >
            <Link to="/area" className="delivery-address-form-div1">
                <div style={{ position: "relative", width: "100%", minHeight: "48px" }}>
                    <label className="label-name" style={{ position: "relative", top: 0 }}>
                        {language === "ltr" ? "Area" : "منطقة"}
                    </label>
                    <div className='delivery-selected-address'>
                        <span className='delivery-selected-address-area'>
                            {
                                areaDetails?.area === "" ?
                                    language == "ltr" ? "Select delivery location" : "حدد موقع التسليم"
                                    :
                                    language == "ltr" ? areaDetails?.area : areaDetails?.ar_area
                            }
                        </span>
                        <i className="fa fa-angle-right right-arrow"></i>
                    </div>
                </div>
            </Link>
            <div className="form-tab-section checkout-one " style={{ paddingTop: "20px" }}>
                <NewAddressFormField
                    blockValidation={blockValidation}
                    streetValidation={streetValidation}
                    houseValidation={houseValidation}
                    errorState={errorState}
                    addressDetails={addressDetails}
                    setAddressDetails={setAddressDetails}
                    addressType={addressDetails.addressType}
                    handleMapChanges={handleMapChanges}
                />
            </div>

        </div >
    )
}

export default NewAddressForm