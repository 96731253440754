import React, { useContext } from "react";
import { LanguageContext, VendorContext } from "../App";

function WhatsappSticky() {
  const { language } = useContext(LanguageContext);
  const details = useContext(VendorContext);
  return (
    <div /* style={{ display: 'none' }} */>
      {details?.vendor?.social_media?.whatsapp != "" &&
        details?.vendor?.social_media?.whatsapp ? (
        <div className="sticky-whats">
          <a
            href={`https://api.whatsapp.com/send?phone=${details?.vendor?.social_media?.whatsapp}`}
            rel="noreferrer"
            target="_blank"
          >
            <img
              src={
                language == "ltr"
                  ? "/pictures/Whatsapp logo - En.png"
                  : "/pictures/Whatsapp logo - Ar.png"
              }
              style={{ height: 100, width: "auto" }}
            ></img>
          </a>
        </div>
      ) : null}
    </div>
  );
}

export default WhatsappSticky;
