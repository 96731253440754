import React, { useContext, useEffect } from "react";
import "./termsModal.css";
import { LanguageContext } from "../../App";
import CommonBackButton from "../NewSideBar/Components/CommonBackButton";

const TermsModal = ({ handleClose, isOpen, termsData }) => {
  const { language } = useContext(LanguageContext);

  useEffect(() => {
    const modalContainer = document.getElementById("termsmodal-container");
    const body = document.body;
    if (modalContainer && body) {
      if (isOpen && modalContainer) {
        modalContainer.removeAttribute("class");
        modalContainer.classList.add("open");
        body.classList.add("modal-active");
      } else {
        modalContainer.classList.add("out");
        body.classList.remove("modal-active");
      }
    }
  }, [isOpen]);

  return (
    <div id="termsmodal-container">
      <div className={"modal-background"} onClick={handleClose}>
        <div className={"modal"} style={{ padding: "10px" }}>
          <div
            style={{
              overflowY: "auto",
              height: "80vh",
              paddingRight: language === "ltr" ? 10 : 0,
              overflowX: "hidden",
              paddingLeft: language === "ltr" ? 0 : 10,
              color: "#000",
              background: "#fff",
              borderRadius: "20px",
            }}
            className="paymentTypeMain firstDetailsTab scrollbarPaylinkCard"
          >
            <div className="changeDir">
              <div
                style={{
                  paddingBottom: 15,
                  position: "sticky",
                  top: 0,
                  backgroundColor: "white",
                  width: "100%",
                }}
              >
                <div style={{ margin: 0 }} className="goBackButton">
                  <div
                    onClick={() => {
                      handleClose(false, "");
                    }}
                    style={{
                      marginBottom: "0",
                      paddingTop: "15px",
                      marginLeft: language === "ltr" ? 0 : "-15px",
                      marginRight: language === "ltr" ? "-15px" : 0,
                    }}
                  >
                    <CommonBackButton setBurger={handleClose} />
                  </div>
                </div>
                <div
                  style={{
                    textAlign: "center",
                    fontSize: language === "ltr" ? 18 : 21,
                    color: "#000",
                  }}
                  className="payViaText"
                >
                  {language === "ltr"
                    ? termsData?.title
                    : termsData?.arabic_name}
                </div>
              </div>
              <div
                style={{
                  padding: "0 8px",
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      language === "ltr"
                        ? termsData?.description
                        : termsData?.arabic_description,
                  }}
                  style={{ textAlign: "start" }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsModal;
