import React, { useContext } from 'react'
import { LanguageContext } from '../../../App'

const CommonHeader = ({ englishHeader, arabicHeader }) => {
    const { language } = useContext(LanguageContext)
    return (
        <div className='commonHeader-Div'>
            {
                language === "ltr" ?
                    englishHeader :
                    arabicHeader
            }
        </div>
    )
}

export default CommonHeader