import React, { useContext, useEffect, useState } from "react";
import CategoryProductList from "../../CategoryProductList";
import { LanguageContext } from "../../../App";
import SubCategoriesList from "../../SubCategories/SubCategoriesList";
import SubCategoriesSideScroll from "../../SubCategories/SubCategoriesSideScroll";

function CategoryDropList({ category, idx, setcategoryopen, categorynow }) {
  const [loading, setLoading] = useState(false);
  const { language } = useContext(LanguageContext);

  return (
    <div
      id={`category${idx}`}
      className="accordion-container category-accordion-div"
    >
      <button
        onClick={() => {
          setcategoryopen(true);
        }}
        className={`category-accordion ${
          categorynow == "" || categorynow == category?.category_name
            ? "active"
            : ""
        }`}
      >
        {language === "ltr"
          ? category?.category_name
          : category?.category_name_ar}
      </button>
      <div
        className={`area-list-collapse ${
          categorynow == "" || categorynow == category?.category_name
            ? "active"
            : ""
        }`}
      >
        {category?.is_subcategory ? (
          <div className="product-category-view">
            <ul className="product-category-list">
              {category?.products.map((item) => {
                return (
                  <li className="product-category-pad">
                    <SubCategoriesSideScroll
                      item={item}
                    ></SubCategoriesSideScroll>
                  </li>
                );
              })}
            </ul>
          </div>
        ) : (
          <CategoryProductList
            products={category?.products}
            loading={loading}
          ></CategoryProductList>
        )}
      </div>
    </div>
  );
}

export default CategoryDropList;
