import React from 'react'

function MobileFooter() {
    return (
        <div className='back-grey pt-2'>
            <div className='bg-white mobile-footer-div d-flex justify-content-center'>
                <footer>
                    <a href="http://payzah.com/">
                        <span>
                            <img src='pictures/payzahlogoicon.png' className='mobile-footer-image' />
                        </span>
                        <span className='mobile-footer-text'>
                            Powered by Payzah
                        </span>
                    </a>
                </footer>
            </div>
        </div>
    )
}

export default MobileFooter