import React, { useContext, useEffect } from "react";
import "./longButtonsModal.css";
import { LanguageContext } from "../../../../App";
import CommonBackButton from "../../../NewSideBar/Components/CommonBackButton";
import Arrow from "../Arrow";

const LongButtonsModal = ({ isOpen, handleClose }) => {
  const { language } = useContext(LanguageContext);

  useEffect(() => {
    const modalContainer = document.getElementById("termsmodal-container");
    const body = document.body;
    if (modalContainer && body) {
      if (isOpen && modalContainer) {
        modalContainer.removeAttribute("class");
        modalContainer.classList.add("open");
        body.classList.add("modal-active");
      } else {
        modalContainer.classList.add("out");
        body.classList.remove("modal-active");
      }
    }
  }, [isOpen]);

  return (
    <div>
      <div id="termsmodal-container">
        <div className={"modal-background"} onClick={handleClose}>
          <div className={"modal"} style={{ padding: "10px" }}>
            <div
              style={{
                overflowY: "auto",
                height: "36vh",
                paddingRight: language === "ltr" ? 10 : 0,
                overflowX: "hidden",
                paddingLeft: language === "ltr" ? 0 : 10,
                color: "#000",
                background: "#fff",
                borderRadius: "20px",
                width: "100%",
              }}
              className="paymentTypeMain firstDetailsTab scrollbarPaylinkCard"
            >
              <div
                className="changeDir"
                style={{ width: "100%", padding: "0 50px" }}
              >
                <div
                  style={{
                    paddingBottom: 15,
                    position: "sticky",
                    top: 0,
                    backgroundColor: "white",
                    width: "100%",
                  }}
                >
                  <div style={{ margin: 0 }} className="goBackButton">
                    <div
                      onClick={() => {
                        handleClose(false, "");
                      }}
                      style={{
                        marginBottom: "0",
                        paddingTop: "15px",
                        marginLeft: language === "ltr" ? 0 : "-15px",
                        marginRight: language === "ltr" ? "-15px" : 0,
                      }}
                    >
                      <CommonBackButton
                        setBurger={handleClose}
                        isBackButton={null}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: language === "ltr" ? 16 : 18,
                      color: "#000",
                    }}
                    className="payViaText"
                  >
                    {language === "ltr"
                      ? "Select your payment method"
                      : "إختر طريقة الدفع"}
                  </div>
                </div>
                <div className="lognButtonMainDiv">
                  <div className="imageDiv">
                    <img
                      src={"pictures/newApplePayButton.png"}
                      className="image"
                    />
                  </div>
                  <div className="buttonText">
                    {language === "ltr" ? "Apple Pay" : "أبل الدفع"}
                  </div>

                  <div className="arrow">
                    <Arrow height="20" />
                  </div>
                </div>

                <div className="lognButtonMainDiv">
                  <div className="imageDiv">
                    <img
                      src={"pictures/knet icon mini.png"}
                      style={{ height: "20px" }}
                    />
                  </div>
                  <div className="buttonText">
                    {language === "ltr" ? "K-NET" : "كي نت"}
                  </div>
                  <div className="arrow">
                    <Arrow height="20" />
                  </div>
                </div>

                <div className="lognButtonMainDiv">
                  <div className="imageDiv">
                    <img src={"pictures/visa.png"} style={{ height: "15px" }} />
                    <img
                      src={"pictures/master.png"}
                      style={{ height: "15px" }}
                    />
                  </div>
                  <div className="buttonText">
                    {language === "ltr" ? "Credit Card" : "بطاقة إئتمان"}
                  </div>
                  <div className="arrow">
                    <Arrow height="20" />
                  </div>
                </div>

                <div className="lognButtonMainDiv">
                  <div className="imageDiv">
                    <img
                      src={"pictures/icons8-money-64.png"}
                      style={{ height: "30px" }}
                    />
                  </div>
                  <div className="buttonText">
                    {language === "ltr"
                      ? "Cash On Delivery"
                      : "الدفع عند الاستلام"}
                  </div>
                  <div className="arrow">
                    <Arrow height="20" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LongButtonsModal;
