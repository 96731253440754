import moment from "moment";
import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { AreaContext, LanguageContext, VendorContext, VendorSlugContext } from "../../../App";
import "moment/locale/ar-sa";
import ModeSelector from "../../NewOrderMode/Component/ModeSelector";

function OrderType() {
  const history = useHistory();
  const { language } = useContext(LanguageContext);
  const details = useContext(VendorContext);
  const { areaDetails } = useContext(AreaContext);
  const vendorSlug = useContext(VendorSlugContext);

  return (
    <div className="order-type-select">
      <ModeSelector details={details} language={language} />
      <div
        className="tab-content"
        style={{ borderBottom: "0.5px solid #d9d9d9" }}
      >
        <div
          role="tabpanel"
          className={`tab-pane ${areaDetails.type === "delivery" ? "active" : ""
            }`}
          id="delivery"
        >
          <div className="delivery-select">
            <Link to={`/area`} className="delivery-detail-selected">
              <Link to={`/area`}>{language === "ltr" ? "Area" : "منطقة"}</Link>
              <Link to={`/area`}>
                <span style={{ color: details?.vendor?.vendor_color }}>
                  {areaDetails?.area != ""
                    ? language === "ltr"
                      ? "Kuwait"
                      : ""
                    : ""}
                  {areaDetails?.area != "" ? ", " : ""}
                  {language === "ltr"
                    ? areaDetails?.area != ""
                      ? areaDetails?.area
                      : "Select Your Delivery Location"
                    : areaDetails?.area != ""
                      ? areaDetails?.ar_area
                      : "حدد موقع التسليم الخاص بك"}
                </span>
                <i
                  style={{ color: details?.vendor?.vendor_color }}
                  className="fa fa-angle-right right-arrow"
                ></i>
              </Link>
            </Link>
            {areaDetails?.area != "" ? (
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  if (!areaDetails?.customDelivery) history.push("/timing");
                }}
                className="delivery-detail-selected"
              >
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  {language === "ltr" ? "Delivery Time" : "وقت التوصيل"}
                </Link>
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                >
                  <span style={{ color: details?.vendor?.vendor_color }}>
                    {areaDetails?.now == 1
                      ? language === "ltr"
                        ? `${!areaDetails?.customDelivery
                          ? "Delivery Within"
                          : ""
                        } ${areaDetails?.deliveryTiming}`
                        : `${!areaDetails?.customDelivery
                          ? "التوصيل سيكون خلال"
                          : ""
                        } ${areaDetails?.ar_deliveryTiming}`
                      : moment(areaDetails?.laterDeliveryTiming)
                        .locale("en")
                        .format("DD") +
                      " " +
                      moment(areaDetails?.laterDeliveryTiming)
                        .locale(language == "ltr" ? "en" : "ar-sa")
                        .format("MMMM") +
                      moment(areaDetails?.laterDeliveryTiming)
                        .locale("en")
                        .format(", yyyy hh:mm ") +
                      moment(areaDetails?.laterDeliveryTiming)
                        .locale(language == "ltr" ? "en" : "ar-sa")
                        .format("A")}
                  </span>
                  <i
                    style={{ color: details?.vendor?.vendor_color }}
                    className="fa fa-angle-right right-arrow"
                  ></i>
                </Link>
              </Link>
            ) : null}
          </div>
        </div>
        {details?.vendor?.is_pickup == 1 && (
          <div
            role="tabpanel"
            className={`tab-pane ${areaDetails.type === "pickup" ? "active" : ""
              }`}
            id="pickup"
          >
            <div className="delivery-select">
              <Link to={`/area`} className="delivery-detail-selected">
                <Link to={`/area`}>
                  {language === "ltr" ? "Branch" : "أفرعنا"}
                </Link>
                <Link to={`/area`}>
                  <span style={{ color: details?.vendor?.vendor_color }}>
                    {language === "ltr"
                      ? areaDetails?.branch != ""
                        ? areaDetails?.branch
                        : "Select Branch"
                      : areaDetails?.branch != ""
                        ? areaDetails?.ar_branch
                        : "حدد الفرع"}
                  </span>
                  <i
                    style={{ color: details?.vendor?.vendor_color }}
                    className="fa fa-angle-right right-arrow"
                  ></i>
                </Link>
              </Link>

              {
                vendorSlug === "alawael-bilingual-school" ?
                  null :
                  <>
                    {areaDetails?.branch != "" ? (
                      <Link
                        onClick={(e) => {
                          e.preventDefault();
                          history.push("/timing");
                        }}
                        className="delivery-detail-selected"
                      >
                        <Link
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          {language === "ltr" ? "Pickup Time" : "وقت الإستلام"}
                        </Link>
                        <Link
                          onClick={(e) => {
                            e.preventDefault();
                          }}
                        >
                          <span style={{ color: details?.vendor?.vendor_color }}>
                            {areaDetails?.now == 1
                              ? language === "ltr"
                                ? `${areaDetails?.deliveryTiming}`
                                : `${areaDetails?.ar_deliveryTiming}`
                              : moment(areaDetails?.laterDeliveryTiming)
                                .locale("en")
                                .format("DD") +
                              " " +
                              moment(areaDetails?.laterDeliveryTiming)
                                .locale(language == "ltr" ? "en" : "ar-sa")
                                .format("MMMM") +
                              moment(areaDetails?.laterDeliveryTiming)
                                .locale("en")
                                .format(", yyyy hh:mm ") +
                              moment(areaDetails?.laterDeliveryTiming)
                                .locale(language == "ltr" ? "en" : "ar-sa")
                                .format("A")}
                          </span>
                          <i
                            style={{ color: details?.vendor?.vendor_color }}
                            className="fa fa-angle-right right-arrow"
                          ></i>
                        </Link>
                      </Link>
                    ) : null}
                  </>
              }

            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default OrderType;
