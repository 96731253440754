import React from 'react'

const ClockIcon = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="19" viewBox="0 -960 960 960" width="19" fill={color}>
            <path d="m620.924-316.924 37.152-37.152-152.077-152.087V-720h-51.998v236.154l166.923 166.922ZM480.067-116.001q-75.211 0-141.392-28.42t-115.994-78.21q-49.814-49.791-78.247-116.087t-28.433-141.673q0-75.378 28.42-141.746 28.42-66.369 78.21-115.682 49.791-49.314 116.087-77.747t141.673-28.433q75.378 0 141.753 28.66 66.374 28.66 115.473 77.79 49.099 49.131 77.74 115.549 28.642 66.417 28.642 141.933 0 75.211-28.42 141.392t-77.71 115.994q-49.291 49.814-115.76 78.247-66.468 28.433-142.042 28.433ZM480-480Zm.477 312q129.477 0 220.5-91.5T792-480.477q0-129.477-91.023-220.5T480.477-792Q351-792 259.5-700.977t-91.5 220.5Q168-351 259.5-259.5T480.477-168Z" />
        </svg>
    )
}

export default ClockIcon