import React, { useContext, useEffect } from "react";
import { VendorContext } from "../../App";
import BranchDetails from "./components/BranchDetails";
import SnapPixel from "react-snapchat-pixel";
import CommonBack from "../NewCheckOutPage/Components/CommonBack";

function BranchPage() {
  const details = useContext(VendorContext);

  useEffect(() => {
    if (
      details?.vendor?.snap_pixel_code &&
      details?.vendor?.snap_pixel_code != ""
    )
      SnapPixel.pageView();
  }, []);

  return (
    <>
      <CommonBack
        variant={"white"}
      />
      <BranchDetails></BranchDetails>
    </>
  );
}

export default BranchPage;
