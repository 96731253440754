import React, { useContext, useEffect, useState } from "react";
import $ from "jquery";
import TogglecategoryProductList from "../../TogglecategoryProductList";
import { LanguageContext } from "../../../App";
import SubCategoriesList from "../../SubCategories/SubCategoriesList";

function ProductSideView({
  selectcategorynow,
  toggleView,
  indexId,
  categories,
  setActiveCategory,
  setcategoryopen,
  userClick,
}) {
  const [loading, setloading] = useState(false);
  const { language } = useContext(LanguageContext);
  const [isScroll, setisScroll] = useState(false);
  useEffect(() => {
    if (selectcategorynow !== null && toggleView == 0) {
      $("html, body").animate(
        {
          scrollTop:
            $(`#sidecategory${indexId}`).offset().top -
            (window.screen.width < 991 ? 107 : 59),
        },
        "3000"
      );
    }
  }, [indexId]);
  useEffect(() => {
    if (
      toggleView == 0 &&
      categories &&
      selectcategorynow != categories[0].category_id
    ) {
      let setFirst = false;
      categories.map((element, i) => {
        const activediv = document
          .getElementById(`sidecategory${i}`)
          .getBoundingClientRect();
        if (activediv.top > 59) {
          setFirst = true;
        } else {
          setFirst = false;
        }
      });
      if (setFirst) {
        setActiveCategory(categories[0].category_id);
      }
    }
  }, []);

  useEffect(() => {
    if (toggleView == 0 && categories && !userClick) {
      categories.map((element, i) => {
        const activediv = document
          .getElementById(`sidecategory${i}`)
          .getBoundingClientRect();
        if (activediv.top > 0 && activediv.top < 59) {
          setActiveCategory(element.category_id);
        }
      });
    }
  }, [isScroll]);
  window.onscroll = (e) => {
    setisScroll((prev) => !prev);
  };
  return (
    <div className="back-grey">
      {categories.map((element, i) => (
        <div className="area-list-collapse toggle-productList-div">
          <div
            className="toggle-product-category-name"
            onClick={() => setcategoryopen(true)}
            id={`sidecategory${i}`}
          >
            {language == "ltr"
              ? element?.category_name
              : element?.category_name_ar}
          </div>
          {element?.is_subcategory ? (
            <SubCategoriesList categories={element?.products} />
          ) : (
            <TogglecategoryProductList
              products={element?.products}
              loading={loading}
            ></TogglecategoryProductList>
          )}
        </div>
      ))}
    </div>
  );
}

export default ProductSideView;
