/**
 * @desc This is the single booking place header
 */
import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { deleteB } from "../../../api";
import {
  BookingSetContext,
  LanguageContext,
  VendorContext,
} from "../../../App";

function BookingPlaceHeader() {
  const { language } = useContext(LanguageContext);
  const { bookingSets, setBookingSets } = useContext(BookingSetContext);
  const details = useContext(VendorContext);
  return (
    <header className="delivery-order-header">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
          <p
            className="delivery-order-title text-center"
            style={{ position: "relative" }}
          >
            <div
              className="back-block-float"
              style={
                language == "ltr"
                  ? { position: "absolute", left: 0 }
                  : { position: "absolute", right: 0 }
              }
            >
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  if (bookingSets.selectedPlace.mainVendor) {
                    deleteB({
                      vendorId: details.vendor.booking_vendor_id,
                      userId: localStorage.getItem("userID"),
                      mainVendor: bookingSets.selectedPlace.mainVendor,
                    });
                  }
                  setBookingSets((sets) => ({
                    ...sets,
                    placeClicked: false,
                    selectedPlace: {},
                  }));
                }}
              >
                <img
                  className="back-button"
                  src="https://payzah.net/production770/ecommercelayout/side/images/left-arrow.png"
                  alt="back button"
                ></img>
              </Link>
            </div>
            {language == "ltr"
              ? `${details?.vendor?.place_label} Details`
              : `${details?.vendor?.place_label_ar} المنتج`}
          </p>
        </div>
      </div>
    </header>
  );
}

export default BookingPlaceHeader;
