/**
 * @desc This is the component for seleting the booking type
 */
import React, { useContext } from "react";
import { LanguageContext, VendorContext } from "../../../App";

function BookingType({ bookingType, setBookingType }) {
  const { language } = useContext(LanguageContext);
  const details = useContext(VendorContext);
  return (
    <div className="row" style={{ height: "auto", margin: 0 }}>
      <div className="small-col" style={{ width: "100%" }}>
        <div className="order-type-select">
          <ul className="nav nav-tabs order-type-select-box" role="tablist">
            <div
              className={
                window.location.host.replace(/^www\./, "") !== "playon.today"
                  ? "slider"
                  : "slider temp-slider"
              }
              style={
                window.location.host.replace(/^www\./, "") !== "playon.today"
                  ? language === "ltr"
                    ? {
                      transform:
                        bookingType === 1
                          ? "translate(-50%)"
                          : "translate(50%)",
                    }
                    : {
                      transform:
                        bookingType === 1
                          ? "translate(50%)"
                          : "translate(-50%)",
                    }
                  : language === "ltr"
                    ? {
                      transform:
                        bookingType === 1
                          ? "translate(-100%)"
                          : "translate(0%)",
                    }
                    : {
                      transform:
                        bookingType === 1
                          ? "translate(0%)"
                          : "translate(-100%)",
                    }
              }
            ></div>
            <li
              className="nav-item"
              style={
                window.location.host.replace(/^www\./, "") == "playon.today"
                  ? { maxWidth: 110 }
                  : {}
              }
            >
              <span
                onClick={() => setBookingType(() => 1)}
                className={`nav-link ${bookingType === 1 ? "active" : ""}`}
                role="tab"
                data-toggle="tab"
                aria-selected={bookingType === 1}
              >
                <p
                  className="ordertype-link-title"
                  style={{
                    color: `${details?.vendor?.vendor_color} !important`,
                  }}
                >
                  {language === "ltr" ? "Short Term" : "قصير المدى"}
                </p>
              </span>
            </li>
            <li
              className="nav-item"
              style={
                window.location.host.replace(/^www\./, "") == "playon.today"
                  ? { maxWidth: 110 }
                  : {}
              }
            >
              <span
                onClick={() => setBookingType(() => 2)}
                className={`nav-link ${bookingType === 2 ? "active" : ""}`}
                role="tab"
                data-toggle="tab"
                aria-selected={bookingType === 2}
              >
                <p
                  className="ordertype-link-title"
                  style={{
                    color: `${details?.vendor?.vendor_color} !important`,
                  }}
                >
                  {language === "ltr" ? "Long Term" : "طويل المدى"}
                </p>
              </span>
            </li>
            {window.location.host.replace(/^www\./, "") == "playon.today" && (
              <li
                className="nav-item"
                style={
                  window.location.host.replace(/^www\./, "") == "playon.today"
                    ? { maxWidth: 110 }
                    : {}
                }
              >
                <span
                  onClick={() =>
                    window.open("https://shop.playon.today", "_blank")
                  }
                  className={`nav-link `}
                >
                  <p
                    className="ordertype-link-title"
                    style={{
                      color: `black`,
                    }}
                  >
                    {language === "ltr" ? "Store" : "متجر"}
                  </p>
                </span>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default BookingType;
