import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import "./fonts/SFTfont.css";
import "./fonts/SFT-font/SFTSchriftedSansTRIAL-DemiBold-BF6413e1ee404fe.otf";
import "./fonts/SFT-font/SFTSchriftedSansTRIAL-ExLightItComp-BF6413e1edb3a54.otf";
import "./fonts/SFT-font/SFTSchriftedSansTRIAL-ExtraLight-BF6413e1ed0f558.otf";
import "./fonts/SFT-font/SFTSchriftedSansTRIAL-Medium-BF6413e2024d36d.ttf";
import "./fonts/SFT-font/SFTSchriftedSansTRIAL-Bold-BF6413e20329194.ttf";

// import "./fonts/OpenSans/OpenSans-Bold.ttf";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorker.register();
