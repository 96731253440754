import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

const BrokenImage = ({ src, alt, fallbackSrc, styles, imgClass }) => {
  const [imgSrc, setImgSrc] = useState(src);

  const handleError = () => {
    setImgSrc(fallbackSrc);
  };

  return (
    <LazyLoadImage
      style={styles ?? {}}
      src={imgSrc}
      className={imgClass ?? {}}
      alt={alt ?? ""}
      onError={handleError}
    ></LazyLoadImage>
  );
};

export default BrokenImage;
