import React, { useContext } from 'react'
import { LanguageContext } from '../../../App'
import BackIcon from '../../../SVGs/BackIcon'
import CrossIcon from '../../../SVGs/CrossIcon'

const CommonBackButton = ({ isBackButton, setBurger }) => {
    const { language, setLanguage } = useContext(LanguageContext)
    return (
        <div className='commonBackButton-mainDiv'>
            <div>
                {isBackButton &&
                    <div className='commonBackButton-backdiv' onClick={() => {
                        isBackButton()
                    }}>
                        <div className='commonBackButton-backdiv-arrow'><BackIcon /></div>
                        <div className='commonBackButton-backdiv-text'>
                            {language === "ltr" ? "Back" : "تراجع"}
                        </div>
                    </div>

                }
            </div>
            <div className='commonBackButton-crossdiv' onClick={() => setBurger(false)}>
                <CrossIcon size={"17"} />
            </div>
        </div>
    )
}

export default CommonBackButton