import React, { useContext } from "react";
import { LanguageContext, SearchContext } from "../../../App";

function SearchNone() {
  const { search } = useContext(SearchContext);
  const { language } = useContext(LanguageContext);
  return (
    <div className="products-main-div">
      <div className="p-3">
        <h3 style={{ textAlign: "center" }}>
          {language == "ltr"
            ? `No results for "${search}"`
            : `لا توجد نتائج لـ "${search}"`}
        </h3>
      </div>
    </div>
  );
}

export default SearchNone;
