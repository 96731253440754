import React, { Suspense, useContext, useEffect, useState } from 'react'
import { ContactContext } from '../../../App';

const NewMapAddress = React.lazy(() => import('./NewMapAddress'))


const DeliveryMapContainer = ({ selectedArea, handleMapChanges, markerPosition, setMarkerPosition, selectedBounds, setSelectedBounds, triggerClick }) => {
    const {
        addressDetails,
        setAddressDetails,
    } = useContext(ContactContext);
    const [mapBounds, setMapBounds] = useState(true);
    useEffect(() => {
        triggerClick()
    }, [])


    return (
        <div style={{ position: "relative", margin: "0 -36px" }}>
            <Suspense fallback={<div>Loading...</div>}>
                <NewMapAddress
                    center={{ lat: addressDetails?.lat, lng: addressDetails?.lng }}
                    currentCenter={{
                        lat: addressDetails?.fixedLat,
                        lng: addressDetails?.fixedLng,
                    }}
                    setAddressDetails={setAddressDetails}
                    setMapBounds={setMapBounds}
                    selectedArea={selectedArea}
                    addressDetails={addressDetails}
                    handleMapChanges={handleMapChanges}
                    markerPosition={markerPosition}
                    setMarkerPosition={setMarkerPosition}
                    selectedBounds={selectedBounds}
                    setSelectedBounds={setSelectedBounds}
                    triggerClick={triggerClick}
                />
            </Suspense>
        </div>
    )
}

export default DeliveryMapContainer