import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AreaContext, LanguageContext } from "../../../App";

function BranchList() {
  const [branchs, setBranchs] = useState([]);
  const { language } = useContext(LanguageContext);
  const { areaDetails } = useContext(AreaContext);

  useEffect(() => {
    if (areaDetails.data.branch) {
      setBranchs(areaDetails.data.branch);
    }
  }, [areaDetails.data.branch]);

  return (
    <div>
      <div className="branch-list-container">
        <ul className="branch-list">
          {branchs?.map((branch, i) => (
            <li className="branch-list-item">
              <Link to={`/branch=${i}`} className="branch-details">
                <h2 className="branch-name">
                  {language === "ltr" ? branch?.name : branch?.arabic_name}
                </h2>
                <p className="branch-address">
                  {language === "ltr"
                    ? branch?.address
                    : branch?.arabic_address}
                </p>
              </Link>
              <Link to={`/branch=${i}`} className="branch-info">
                <span>i</span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default BranchList;
