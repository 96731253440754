import React, { useContext, useEffect, useState } from "react";
import {
  AreaContext,
  CartContext,
  ContactContext,
  InterNationalContext,
  LanguageContext,
  VendorContext,
  VendorSlugContext,
} from "../../App";
import CommonHeader from "../NewCheckOutPage/Components/CommonHeader";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import NewOrderProductList from "./Components/NewOrderProductList";
import "./checkOrderDetails.css";
import BuyerDetails from "./Components/BuyerDetails";
import NewDeliveryDetails from "./Components/NewDeliveryDetails";
import NewDeliveryCompany from "./Components/NewDeliveryCompany";
import {
  getDeliveryCompanies,
  updateDeliveryCharges,
} from "../APIS/checkOutApi";
import NewPaymentSelector from "./Components/NewPaymentSelector";
import axios from "axios";
import NewPromocode from "./Components/NewPromocode";
import NewAmountDetails from "./Components/NewAmountDetails";
import { API_URL, tele } from "../../services/constants";
import ReactPixel from "react-facebook-pixel";
import SnapPixel from "react-snapchat-pixel";
import TiktokPixel from "tiktok-pixel";
import moment from "moment";
import { checkoutTag } from "../../checkouttag";
import CheckoutModal from "../CheckoutModal";
import ModalClosed from "../ModalClosed";
import Spinner from "../../SVGs/Spinner";
import DeliveryNotServing from "./Components/DeliveryNotServing";
import CommonBack from "../NewCheckOutPage/Components/CommonBack";
import DeliveryAddressSection from "../InterNationalDelivery/DeliveryAddressSection";
import { saveOrderDetails } from "../APIS/submitOrderApi";
import AppleModal from "./Components/AppleModal";

const NewOrderDetailsPage = () => {
  const details = useContext(VendorContext);
  const vendorSlug = useContext(VendorSlugContext);
  const { language } = useContext(LanguageContext);
  const [successPromocode, setSuccessPromocode] = useState("");
  const [promocode, setPromocode] = useState("");
  const { cart, setCart } = useContext(CartContext);
  const [width, setWidth] = useState(0);
  const [companyData, setCompanyData] = useState();
  const { areaDetails, setAreaDetails } = useContext(AreaContext);
  const [apply, setApply] = useState("");
  const [loading, setLoading] = useState(false);
  const [note, setNote] = useState(false);
  const [popup, setPopup] = useState({ show_popup: 0 });
  const [notServing, setNotServing] = useState(0);
  const [deliveryKm, setDeliveryKm] = useState();
  const {
    addressDetails,
    setAddressDetails,
    contactDetails,
    payment,
    setPayment,
  } = useContext(ContactContext);
  const history = useHistory();
  const [deliveryCharge, setDeliveryCharge] = useState(0);
  const { internationalDelivery } = useContext(InterNationalContext);
  const [showAppleModal, setShowAppleModal] = useState(false);

  const checkAllCondition = () => {
    if (!cart?.cartCount) {
      history.push(`/`);
    } else if (!contactDetails.name && !contactDetails.phone) {
      history.push("/contact-details");
    } else if (
      areaDetails?.type == "delivery" &&
      !addressDetails?.block &&
      !addressDetails?.street &&
      addressDetails?.house &&
      (internationalDelivery.delivery_country_code.toLowerCase() === "kw" ||
        details.vendor.international_delivery === "3" ||
        details.vendor.international_delivery === "")
    ) {
      history.push("/delivery-address");
    } else if (
      areaDetails?.type == "pickup" &&
      !areaDetails?.area &&
      !areaDetails?.branch
    ) {
      history.push(`/area`);
    } else if (
      internationalDelivery.delivery_country_code.toLowerCase() !== "kw" &&
      internationalDelivery.delivery_address1 !== "" &&
      internationalDelivery.delivery_address2 !== ""
    ) {
      history.push("/checkout");
    } else {
      return true;
    }
  };
  useEffect(() => {
    if (cart && cart.deliveryCharge) {
      setDeliveryCharge(cart.deliveryCharge);
    }
  }, [cart]);

  useEffect(() => {
    (async () => {
      const check = checkAllCondition();
      if (
        check &&
        areaDetails?.type == "delivery" &&
        areaDetails?.data?.branch &&
        (internationalDelivery.delivery_country_code.toLowerCase() === "kw" ||
          details.vendor.international_delivery === "3" ||
          details.vendor.international_delivery === "")
      ) {
        let selectedArea = areaDetails?.area;
        if (areaDetails?.area.includes("Al")) {
          let newArea = areaDetails.area
            .split(" ")
            .filter((ele, i) => i !== 0)
            .join(" ");
          selectedArea = newArea;
        }
        const encodedPlaceName = encodeURIComponent(
          `street ${addressDetails.street} ,block ${addressDetails.block} ,${selectedArea}, Kuwait`
        );
        const respones = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedPlaceName}&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`
        );
        if (respones.status === 200) {
          let getSelectedAreaDetails = [];
          if (respones?.data?.results.length == 1) {
            getSelectedAreaDetails.push(respones?.data?.results[0]);
          } else {
            respones?.data?.results.map((ele) =>
              ele.address_components.map((element) => {
                if (
                  element.short_name.toLowerCase() ==
                    selectedArea.toLowerCase() ||
                  element.long_name.toLowerCase() == selectedArea.toLowerCase()
                ) {
                  getSelectedAreaDetails.push(ele);
                }
              })
            );
          }
          let lat = getSelectedAreaDetails[0]?.geometry?.location?.lat;
          let lng = getSelectedAreaDetails[0]?.geometry?.location?.lng;
          if (lat && lng) {
            setAddressDetails((a) => ({
              ...a,
              lat: lat,
              lng: lng,
            }));
            const branch_latlng = areaDetails?.data?.branch?.filter(
              (branch) => branch.id == areaDetails?.branchForArea.id
            );
            const origin = new window.google.maps.LatLng(
              String(branch_latlng[0].lat),
              String(branch_latlng[0].lng)
            );
            const destination = new window.google.maps.LatLng(
              String(lat),
              String(lng)
            );
            const service = new window.google.maps.DistanceMatrixService();
            const request = {
              origins: [origin],
              destinations: [destination],
              travelMode: "DRIVING",
              unitSystem: window.google.maps.UnitSystem.METRIC, // Specify metric units for kilometers
            };

            service.getDistanceMatrix(request, (response, status) => {
              if (
                status === "OK" &&
                response.rows[0].elements[0].status === "OK"
              ) {
                const distanceInMeters =
                  response.rows[0].elements[0].distance.value;
                const distanceInKilometers = distanceInMeters / 1000;
                setDeliveryKm(distanceInKilometers);
              } else {
                console.error("Error:", status);
              }
            });
          }
        }
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (deliveryKm && areaDetails?.type == "delivery") {
        setLoading(true);
        const response = await getDeliveryCompanies({
          vendor_id: details.vendor.vendors_id,
          product: cart.cartItems,
          distance: deliveryKm,
          area_id: areaDetails?.area_id,
          block_id: addressDetails.block,
          ecommerce_vendor_id: details.vendor.ecommerce_vendor_id,
          branch_id: areaDetails?.branchForArea.id,
          destination: {
            latitude: addressDetails.lat,
            longitude: addressDetails.lng,
          },
          block: addressDetails.block,
          street: addressDetails.street,
          avenue: addressDetails.avenue,
          house_no: addressDetails.house,
          floor_no: addressDetails.floor,
          flat_no: addressDetails.flat,
          time: areaDetails?.now,
          schedule_time: areaDetails?.deliveryTiming,
          preorder_on: moment(areaDetails?.laterDeliveryTiming)
            .locale("en")
            .format("YYYY-MM-DD HH:mm:ss"),
        });
        if (response?.status) {
          if (response.is_activated === "1") {
            if (response.data.is_serving === 0) {
              setNotServing(1);
              setLoading(false);
            } else {
              setCompanyData(response.data);
              setDeliveryCharge(response.data.delivery_charge);
              if (response.data.delivery_charge) {
                getDeliveryCharge(response.data.delivery_charge);
              }
              setLoading(false);
            }
          } else {
            setLoading(false);
          }
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    })();
  }, [deliveryKm]);

  const getDeliveryCharge = async (delivery_charge) => {
    if (delivery_charge) {
      const response = await updateDeliveryCharges(
        vendorSlug,
        details?.vendor?.vendors_id,
        areaDetails?.area_id,
        delivery_charge
      );
      if (response && response.status) {
        setLoading(false);
        setCart(response.data);
      } else {
        setLoading(false);
        history.push("/");
      }
    }
  };

  const checkAvailbility = async (method) => {
    axios
      .post(
        `${API_URL}/change-area`,
        JSON.stringify({
          token: process.env.REACT_APP_TOKEN,
          vendor_id: details?.vendor?.vendors_id,
          area_id: areaDetails?.area_id,
          vendor_slug: vendorSlug,
          user_string: localStorage.getItem("userID"),
        })
      )
      .then((res) => {
        let temp = res.data.data;
        if (temp?.show_popup == 0) {
          submitFunc(method);
        } else {
          setLoading((loading) => false);
          setPopup((pop) => temp);
        }
      })
      .catch((e) => console.log(e));
  };
  const getBranchCordinates = () => {
    const branchlatlng = areaDetails.data.branch.filter(
      (branch) => branch.id === areaDetails.branchForArea.id
    );
    if (branchlatlng[0].lat && branchlatlng[0].lng) {
      return { lat: branchlatlng[0].lat, lng: branchlatlng[0].lng };
    }
  };
  const submitFunc = async (method) => {
    axios
      .post(
        `${API_URL}/save-order-details`,
        JSON.stringify({
          token: process.env.REACT_APP_TOKEN,
          user_string: localStorage.getItem("userID"),
          vendor_slug: vendorSlug,
          ecommerce_vendor_id: details.vendor.ecommerce_vendor_id,

          first_name: contactDetails.name,
          country_code: tele[contactDetails?.phoneCode],
          phone: contactDetails.phone,
          email: contactDetails.email,
          type: areaDetails?.type,
          area_id: areaDetails?.type == "delivery" ? areaDetails?.area_id : "",
          area_name: areaDetails?.type == "delivery" ? areaDetails?.area : "",
          area_name_ar:
            areaDetails?.type == "delivery" ? areaDetails?.ar_area : "",
          branch_id: areaDetails?.branchForArea?.id,
          branch_name: areaDetails?.branchForArea?.english,
          branch_name_ar: areaDetails?.branchForArea?.arabic,
          address_type: addressDetails.addressType,
          block: addressDetails.block,
          street: addressDetails.street,
          avenue: addressDetails.avenue,
          house_no: addressDetails.house,
          floor_no: addressDetails.floor,
          flat_no: addressDetails.flat,
          special_directions: addressDetails.special_directions,
          payment_method:
            cart.knetTotal == 0.0 ||
            cart.codTotal == 0.0 ||
            cart.creditCardTotal == 0.0
              ? "3"
              : method,
          promocode: successPromocode,
          time: areaDetails?.now,
          schedule_time: areaDetails?.deliveryTiming,
          preorder_on: moment(areaDetails?.laterDeliveryTiming)
            .locale("en")
            .format("YYYY-MM-DD HH:mm:ss"),
          vendor_id: details?.vendor?.vendors_id,
          success_url: window.location.origin + "/",
          error_url: window.location.origin + "/",

          // success_url: window.location.origin + "/beta/",
          // error_url: window.location.origin + "/beta/",
          is_pickup: areaDetails?.type == "pickup" ? 1 : 0,
          car_model: contactDetails?.model,
          car_color: contactDetails?.color,
          license_number: contactDetails?.license,
          selected_delivery_company: companyData?.delivery_partner_code || 0,
          user_lat: addressDetails?.lat || 0,
          user_lng: addressDetails?.lng || 0,
          distance_km: deliveryKm || 0,
          address_string: addressDetails.addressString,
          estimated_date: companyData?.estimated_date || 0,
          estimated_time: companyData?.estimated_time || 0,
          // third_part_delivery_charge: companyData?.delivery_charge || 0,
          delivery_charge: deliveryCharge || "",
          ecom_delivery_co_id: companyData?.ecom_delivery_co_id || 0,
          branch_lat: getBranchCordinates().lat,
          branch_lng: getBranchCordinates().lng,
          country_id: "1",
          is_interNational: 0,
        })
      )
      .then((res) => {
        localStorage?.setItem("check", "1");
        if (details?.vendor?.fb_pixel_code != "")
          ReactPixel.track("InitiateCheckout", {
            content_ids: cart?.cartItems?.map((k) => k?.product_id),
            value: cart?.subTotal,
            num_items: cart?.cartCount,
            contents: cart?.cartItems?.map((k) => ({
              id: k?.product_id,
              item_id: k?.item_id,
              name: k?.english_name,
              quantity: k?.quantity,
              value: k?.original_price,
            })),
            currency: "KWD",
          });

        if (details?.vendor?.snap_pixel_code != "")
          SnapPixel.track("START_CHECKOUT", {
            item_ids: cart?.cartItems?.map((k) => k?.product_id),
            content_type: "product",
            price: cart?.subTotal,
            currency: "KWD",
          });

        if (details?.vendor?.vendors_id === "132") {
          TiktokPixel.track("InitiateCheckout");
        }

        if (
          details?.vendor?.google_tag_code != "" &&
          !/^GTM/.test(details?.vendor?.google_tag_code)
        )
          checkoutTag(
            cart?.cartItems?.map((item) => ({
              item_id: item?.product_id,
              item_name: item?.english_name,
              currency: "KWD",
              discount: item?.discount_price,
              price: item?.original_price,
              quantity: item?.quantity,
            }))
          );
        window.location.assign(res.data.data.payment_url);
      })
      .catch((e) => console.log(e));
  };

  const onConfirmOrder = (method) => {
    setLoading((loading) => true);

    axios
      .post(
        `${API_URL}/branches`,
        JSON.stringify({
          token: process.env.REACT_APP_TOKEN,
          vendor_id: details?.vendor?.vendors_id,
          vendor_slug: vendorSlug,
        })
      )
      .then((res) => {
        setAreaDetails((areaDetails) => ({
          ...areaDetails,
          data: { ...areaDetails.data, branch: res.data.data },
        }));
        if (
          !(
            areaDetails?.branchForArea?.start > moment() ||
            moment() > areaDetails?.branchForArea?.end
          )
        )
          checkAvailbility(method);
        else {
          if (
            !res.data.data?.filter(
              (k) => k?.id == areaDetails?.branchForArea?.id
            )[0]?.on_shop_close_purchase == 1
          )
            checkAvailbility(method);
          else {
            setNote((m) => true);
            setLoading((loading) => false);
          }
        }
      })
      .catch((e) => console.log(e));
  };

  const submitInternational = async (method) => {
    const payload = {
      vendorSlug: vendorSlug,
      details: details,
      successPromocode: successPromocode,
      areaDetails: areaDetails,
      internationalDelivery: internationalDelivery,
      payment: method,
      domain: window.location.origin,
      contactDetails: contactDetails,
    };
    setLoading((loading) => true);
    const response = await saveOrderDetails(payload);
    if (response.status) {
      localStorage?.setItem("check", "1");
      if (details?.vendor?.fb_pixel_code != "")
        ReactPixel.track("InitiateCheckout", {
          content_ids: cart?.cartItems?.map((k) => k?.product_id),
          value: cart?.subTotal,
          num_items: cart?.cartCount,
          contents: cart?.cartItems?.map((k) => ({
            id: k?.product_id,
            item_id: k?.item_id,
            name: k?.english_name,
            quantity: k?.quantity,
            value: k?.original_price,
          })),
          currency: "KWD",
        });

      if (details?.vendor?.snap_pixel_code != "")
        SnapPixel.track("START_CHECKOUT", {
          item_ids: cart?.cartItems?.map((k) => k?.product_id),
          content_type: "product",
          price: cart?.subTotal,
          currency: "KWD",
        });

      if (details?.vendor?.vendors_id === "132") {
        TiktokPixel.track("InitiateCheckout");
      }

      if (
        details?.vendor?.google_tag_code != "" &&
        !/^GTM/.test(details?.vendor?.google_tag_code)
      )
        checkoutTag(
          cart?.cartItems?.map((item) => ({
            item_id: item?.product_id,
            item_name: item?.english_name,
            currency: "KWD",
            discount: item?.discount_price,
            price: item?.original_price,
            quantity: item?.quantity,
          }))
        );
      window.location.assign(response.data.data.payment_url);
    } else {
    }
  };

  const startApplePay = (method) => {
    if (
      internationalDelivery.delivery_country_code.toLowerCase() === "kw" ||
      details.vendor.international_delivery === "3" ||
      details.vendor.international_delivery === ""
    ) {
      onConfirmOrder(method);
    } else {
      submitInternational(method);
    }
  };

  return (
    <div className="holder-container" style={{ paddingBottom: "36px" }}>
      <div style={{ margin: "0 -36px" }}>
        <CommonBack
          clickButton={() => {
            if (areaDetails?.type === "pickup") {
              history.push("/contact-details");
            } else {
              history.push("/delivery-address");
            }
          }}
        />
      </div>
      <CommonHeader englishHeader="Checkout" arabicHeader="تفاصيل الطلب" />
      <div className="delivery-details-text">
        {language === "ltr" ? "Items Details" : "تفاصيل عربة التسوق"}
      </div>
      <NewOrderProductList
        setSuccessPromocode={setSuccessPromocode}
        successPromocode={successPromocode}
        deliveryCharge={deliveryCharge}
      />
      <BuyerDetails />
      {internationalDelivery.delivery_country_code.toLowerCase() === "kw" ||
      details.vendor.international_delivery === "3" ||
      details.vendor.international_delivery === "" ? (
        <NewDeliveryDetails
          addressDetails={addressDetails}
          companyData={companyData}
        />
      ) : (
        <DeliveryAddressSection internationalDelivery={internationalDelivery} />
      )}
      {companyData && <NewDeliveryCompany companyData={companyData} />}
      <NewPaymentSelector
        setPayment={setPayment}
        payment={payment}
        setWidth={setWidth}
        width={width}
      />
      {cart?.show_promocode == 1 ? (
        <NewPromocode
          promocode={promocode}
          setPromocode={setPromocode}
          setApply={setApply}
          apply={apply}
          setSuccessPromocode={setSuccessPromocode}
          deliveryCharge={deliveryCharge}
        />
      ) : null}
      <NewAmountDetails
        cart={cart}
        areaDetails={areaDetails}
        language={language}
        details={details}
        payment={payment}
        onConfirmOrder={() => {
          // if (payment == 4) {
          //   if (details?.vendor?.is_apple_pay?.length == 0) {
          //     startApplePay(details?.vendor?.is_apple_pay[0]);
          //   } else {
          //     setShowAppleModal(true);
          //   }
          // } else {
          if (
            internationalDelivery.delivery_country_code.toLowerCase() ===
              "kw" ||
            details.vendor.international_delivery === "3" ||
            details.vendor.international_delivery === ""
          ) {
            onConfirmOrder(payment);
          } else {
            submitInternational(payment);
          }
          // }
        }}
      />
      {/* {showAppleModal && (
        <AppleModal
          closeModal={() => setShowAppleModal(false)}
          onAppleClick={(method) => {
            startApplePay(method);
          }}
        />
      )} */}
      {popup?.show_popup == 1 && (
        <CheckoutModal popup={popup} setPopup={setPopup}></CheckoutModal>
      )}
      {notServing == 1 ? <DeliveryNotServing /> : null}
      {note && <ModalClosed note={note} setNote={setNote}></ModalClosed>}
      {loading && (
        <div
          style={{
            width: "100%",
            height: "100vh",
            position: "fixed",
            top: "0",
            left: "0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "8",
          }}
          className="order-spinner-background"
        >
          <Spinner
            height="50px"
            color={details?.vendor?.vendor_color}
            size="6px"
          />
        </div>
      )}
    </div>
  );
};

export default NewOrderDetailsPage;
