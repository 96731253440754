import axios from "axios";
import { API_URL } from "../../services/constants";

export const getTNC = async ({ vendorSlug, vendors_id }) => {
  try {
    let payload = {
      token: process.env.REACT_APP_TOKEN,
      vendor_slug: vendorSlug,
      vendor_id: vendors_id,
    };

    const response = await axios.post(
      `${API_URL}/tnc`,
      JSON.stringify(payload)
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
