import React, { useContext } from "react";
import { VendorContext } from "../../App";
import './listGridview.css'
import Subcategory from "./components/Subcategory";
import CategoryHead from "./components/CategoryHead";

const ListGridView = () => {
    const details = useContext(VendorContext);

    return <div className="listgridview-maindiv">
        <div id="accordion" >
            {details.categories && details.categories.map((ele, i) =>
                <div key={i} className=''>
                    <div className="" id={`heading-${i}`} data-toggle="collapse" data-target={`#collapse-${i}`} aria-expanded="false" aria-controls={`collapse-${i}`} /* onClick={() => handleAccordian(i)} */>
                        <CategoryHead category={ele} />
                    </div>

                    <div id={`collapse-${i}`} className="collapse show" aria-labelledby={`heading-${i}`} data-parent="#accordion">
                        <div className="accrodian-body row">
                            {
                                ele.products && ele.products.map((subcategory, j) =>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-6 category-tab" key={j}>
                                        <Subcategory subcategory={subcategory} isSubcategory={ele.is_subategory == 1 ? true : false} />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            )}
        </div>
    </div>;
};

export default ListGridView;
