import React, { useContext } from 'react'
import { LanguageContext } from '../../../App'

const VendorDetailsSideBar = ({ vendorData }) => {
    const { language } = useContext(LanguageContext)
    return (
        <div className='vendor-card'>
            <div>
                <img src={
                    language === "ltr" ?
                        vendorData?.english_new_background :
                        vendorData?.arabic_new_background}
                    className='vendor-card-logo' />
            </div>
            <div className='vendor-card-name'>
                {language === "ltr" ? vendorData?.name : vendorData?.name_ar}
            </div>
        </div>
    )
}

export default VendorDetailsSideBar