import React, { useContext, useEffect, useState } from "react";
import { LanguageContext, VendorContext, VendorSlugContext } from "../../App";
import "./sidebar.css";
import SideMenu from "./Components/SideMenu";
import CategoryDrawer from "./Components/CategoryDrawer";
import TermsModal from "../TermsModal/TermsModal";
import { getTNC } from "../APIS/getTNC";

const NewSideBar = ({ setBurger }) => {
  const details = useContext(VendorContext);
  const [openCategories, setOpenCategories] = useState(false);
  const vendorSlug = useContext(VendorSlugContext);
  const { language } = useContext(LanguageContext);

  const [isOpen, setIsOpen] = useState(false);
  const [termsData, setTermsData] = useState();

  const handleTermsClick = async () => {
    try {
      const response = await getTNC({
        vendorSlug: vendorSlug,
        vendors_id: details?.vendor.vendors_id,
      });
      if (response.status) {
        // if (response.data.pdf_name || response.data.pdf_name) {
        //   window.open(
        //     `https://payzah.net/production770//public/tncattachment/${
        //       language === "ltr"
        //         ? response.data.pdf_name ?? response.data.pdf_name_ar
        //         : response.data.pdf_name_ar ?? response.data.pdf_name
        //     }`,
        //     "_blank",
        //     "noopener,noreferrer"
        //   );
        // } else {
        setIsOpen(true);
        setTermsData(response.data);
        // }
      }
    } catch (error) {}
  };
  const handleCategory = () => {
    setOpenCategories(!openCategories);
  };
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, []);

  return (
    <div className="side-bar-backdrop" id="navbar-id">
      {!openCategories && (
        <SideMenu
          details={details}
          setBurger={setBurger}
          handleCategory={handleCategory}
          handleTermsClick={handleTermsClick}
        />
      )}
      {openCategories && (
        <CategoryDrawer
          details={details}
          setBurger={setBurger}
          handleCategory={handleCategory}
        />
      )}
      <TermsModal
        isOpen={isOpen}
        handleClose={() => setIsOpen(false)}
        termsData={termsData}
      />
    </div>
  );
};

export default NewSideBar;
