import React, { useContext, useEffect, useState } from 'react'
import { LanguageContext, VendorContext } from '../../../App'
import $ from "jquery";
function ToggleProductHeader({ setindexId, ActiveCategory, setselectcategorynow, selectcategorynow, toggleView, setToggleView, categories, setcategorynow, setActiveCategory, setUserClick }) {
    const { language } = useContext(LanguageContext)
    const details = useContext(VendorContext)
    const [mainSelect, setMainSelect] = useState('')
    const onCategorySelect = (category, k) => {
        setUserClick(true)
        setselectcategorynow(() => category?.category_id);
        setcategorynow(() => category?.category_id)
        $("#categoryflex").animate(
            {
                scrollLeft:
                    $(`#cathort${k}`).position().left +
                    $(`#cathort${k}`).width() / 2 +
                    $("#categoryflex").scrollLeft() -
                    $("#categoryflex").width() / 4,
            },
            "3000"
        );
        setindexId(k)
        setTimeout(() => {
            setUserClick(false)
        }, "5000");
    };
    const onCategoryScroll = (category, k) => {
        $("#categoryflex").animate(
            {
                scrollLeft:
                    $(`#cathort${k}`).position().left +
                    $(`#cathort${k}`).width() / 2 +
                    $("#categoryflex").scrollLeft() -
                    $("#categoryflex").width() / 4,
            },
            "3000"
        );
    }

    useEffect(() => {
        if (selectcategorynow != null) {
            setMainSelect(selectcategorynow)
        }
    }, [selectcategorynow])
    useEffect(() => {
        if (ActiveCategory != null) {
            setMainSelect(ActiveCategory)
        }
    }, [ActiveCategory])
    useEffect(() => {
        if (ActiveCategory != null) {
            const getelement = categories.filter((element, i) => element.category_id == ActiveCategory)
            onCategoryScroll(getelement[0], categories.indexOf(getelement[0]))
        }
    }, [ActiveCategory])

    return (
        <div className='toggle-product-div'>
            <div className='row'>
                <div className='col-lg-1'>
                    <div className='toggle-productCategory-Button'
                        style={{ width: '100%', height: "100%" }}>
                        <div className='active-1' onClick={() => {
                            if (toggleView == 0)
                                setToggleView(1)
                            else setToggleView(0)
                        }}
                            style={{ display: "flex", alignItems: "center", width: '100%', height: "100%", paddingBottom: '10px' }}
                        >
                            {toggleView == 1 ? (<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill={details?.vendor?.vendor_color} className="bi bi-list-task" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M2 2.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V3a.5.5 0 0 0-.5-.5H2zM3 3H2v1h1V3z" />
                                <path d="M5 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM5.5 7a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9zm0 4a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1h-9z" />
                                <path fill-rule="evenodd" d="M1.5 7a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5V7zM2 7h1v1H2V7zm0 3.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5H2zm1 .5H2v1h1v-1z" />
                            </svg>) :
                                (
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill={details?.vendor?.vendor_color} className="bi bi-grid" viewBox="0 0 16 16">
                                        <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z" />
                                    </svg>
                                )
                            }
                        </div>
                    </div>
                </div>
                <div className='col-lg-11'>
                    <div className='product-toggle-category-div' id={'categoryflex'}>
                        <div className='product-toggle-button-div' >
                            {categories?.map((element, i) =>
                                <div key={i}
                                    id={`cathort${i}`}
                                    onClick={() => onCategorySelect(element, i)}
                                    className={`product-categories ${details?.vendor?.home_page_type == "18" ? "fashion-product-categories" : ""} ${element?.category_id == mainSelect ? "list-select selected-category" : ""
                                        }`}>
                                    {language == "ltr" ? element?.category_name : element?.category_name_ar}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ToggleProductHeader