import React, { useContext } from 'react'
import { CartContext } from '../../../App'
import NewCartCard from './NewCartCard'

const NewOrderProductList = ({ setSuccessPromocode, successPromocode, deliveryCharge }) => {
    const { cart } = useContext(CartContext)
    return (
        <div>
            {
                cart && cart.cartItems && cart.cartItems.map((product, index) =>
                    <NewCartCard key={index} product={product} isLast={index === cart.cartItems.length - 1} setSuccessPromocode={setSuccessPromocode} successPromocode={successPromocode} deliveryCharge={deliveryCharge} />
                )
            }
        </div>
    )
}

export default NewOrderProductList