import React from "react";
import VendorDetailsSideBar from "./VendorDetailsSideBar";
import CommonBackButton from "./CommonBackButton";
import RestSideDrawerContent from "./RestSideDrawerContent";
import SideMenuBottom from "./SideMenuBottom";

const SideMenu = ({ details, setBurger, handleCategory, handleTermsClick }) => {
  return (
    <div className="sideMenu-mainDiv">
      <CommonBackButton setBurger={setBurger} />
      <VendorDetailsSideBar vendorData={details?.vendor} />
      <RestSideDrawerContent setBurger={setBurger} />
      <SideMenuBottom
        vendorData={details?.vendor}
        handleTermsClick={handleTermsClick}
      />
    </div>
  );
};

export default SideMenu;
