import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { LanguageContext, VendorContext } from "../../../App";

function MobileNavigationBar({ setBurger, installable, width }) {
  const { language, setLanguage } = useContext(LanguageContext);
  const details = useContext(VendorContext);
  const [change, setChange] = useState(false);
  const [logo, setLogo] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > (100 * 32) / window.innerHeight) {
      setChange((f) => true);
    } else {
      setChange((f) => false);
    }
    if (window.scrollY - 350 > (100 * 32) / window.innerHeight) {
      setLogo((f) => true);
    } else setLogo((f) => false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const onLanguageChange = () => {
    document
      .getElementsByTagName("html")[0]
      .setAttribute("dir", language.split("").reverse().join(""));
    sessionStorage.setItem("language", language.split("").reverse().join(""));
    setLanguage(language.split("").reverse().join(""));
  };
  return (
    <div
      style={{
        top: installable && width < 992 && !change ? "55px" : "0px",
        transition: "top 1s 0s",
      }}
      className={`mobile-menu-navigation  ${
        change ? "mobile-menu-navigation-white" : ""
      } ${logo ? "mobile-menu-navigation-logo" : ""}`}
    >
      <button onClick={() => setBurger((bur) => !bur)} className="hamburger-ic">
        <i className="fa fa-bars" aria-hidden="true"></i>
      </button>
      <span className="mobile-vendor-center-name">
        <img
          className="logo-mobile-bar"
          style={{
            borderRadius:
              details?.vendor?.home_page_type == "18" ? "0px" : "5px",
          }}
          src={
            language == "ltr"
              ? details?.vendor?.english_new_background
              : details?.vendor?.arabic_new_background
          }
        ></img>
      </span>
      <div className="right-menu">
        {window.location.host != "alrubaiaanlibrary.payzah.store" && (
          <Link
            onClick={(e) => {
              e.preventDefault();
              onLanguageChange();
            }}
            className="language-add"
          >
            {language === "rtl" ? "English" : "عربي"}
          </Link>
        )}
      </div>
    </div>
  );
}

export default MobileNavigationBar;
