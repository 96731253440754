import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

function BookingCarousel({ booking }) {
  return booking?.image ? (
    <OwlCarousel
      dir="ltr"
      items={1}
      className="owl-theme"
      loop={false}
      autoplay={false}
      mouseDrag={false}
      dots={false}
      animateIn="fadeIn"
      animateOut="fadeOut"
    >
      <div className="product-owl-img">
        <img className="img" src={`${booking?.image}`} />
      </div>
    </OwlCarousel>
  ) : null;
}

export default BookingCarousel;
