import React, { useContext } from "react";
import { LanguageContext, VendorContext } from "../../../App";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { LazyLoadImage } from "react-lazy-load-image-component";
import BrokenImage from "../../Common/BrokenImage";

const Subcategory = ({ subcategory, isSubcategory }) => {
  const { language } = useContext(LanguageContext);
  const details = useContext(VendorContext);

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Link
        className="subcatgeory-mainContainer"
        to={
          isSubcategory
            ? `/products=${subcategory.category_slug}`
            : `/product=${subcategory.product_slug}`
        }
      >
        {/* <LazyLoadImage
          src={isSubcategory ? subcategory.category_image : subcategory.image}
          className="category-image img-fluid"
          placeholderSrc={"pictures/ffffff.jpg"}
        ></LazyLoadImage> */}
        <BrokenImage
          src={isSubcategory ? subcategory.category_image : subcategory.image}
          imgClass={"category-image img-fluid"}
          fallbackSrc={
            language === "ltr"
              ? details?.vendor?.english_new_background
              : details?.vendor?.arabic_new_background
          }
        />
        <p className="category-title pb-3">
          {language === "ltr"
            ? subcategory.category_name
            : subcategory.category_name_ar}
        </p>
      </Link>
    </div>
  );
};

export default Subcategory;
