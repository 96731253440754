import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useCallback } from "react";
import { useParams } from "react-router";
import { ClipLoader } from "react-spinners";
import {
  AreaContext,
  CartContext,
  LanguageContext,
  VendorContext,
  VendorSlugContext,
} from "../../App";
import { API_URL } from "../../services/constants";
import ReviewBar from "../HomePage/components/ReviewBar";
import ProductsList from "./components/ProductsList";
import SnapPixel from "react-snapchat-pixel";
import ReactPixel from "react-facebook-pixel";
import sha256 from "sha256";
import CommonBack from "../NewCheckOutPage/Components/CommonBack";

function ProductList({ products, lastBookElementRef, loading, isPageLoading, handleBackClick }) {
  // const [products, setProducts] = useState([]);
  const { category } = useParams();
  const [burger, setBurger] = useState(false);
  const { language } = useContext(LanguageContext);
  const { cart } = useContext(CartContext);
  const vendorSlug = useContext(VendorSlugContext);
  const details = useContext(VendorContext);
  // const [loading, setLoading] = useState(false);
  const { areaDetails } = useContext(AreaContext);
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (
      details?.vendor?.snap_pixel_code &&
      details?.vendor?.snap_pixel_code != ""
    )
      SnapPixel.pageView();
    if (details?.vendor?.fb_pixel_code && details?.vendor?.fb_pixel_code != "")
      ReactPixel.pageView();
  }, []);

  return (
    <>
      <div className="delivery-order-header">
        <CommonBack
          english_title={products?.[0]?.category_name}
          arabic_title={products?.[0]?.category_name_ar}
          variant="light"
          clickButton={handleBackClick}
        />
      </div>
      {products &&
        !loading &&
        (products.length ? (
          <ProductsList
            loading={loading}
            lastBookElementRef={lastBookElementRef}
            products={products}
          ></ProductsList>
        ) : null)}

      {(!products.length && !loading && !isPageLoading) ?
        <div style={{ padding: "0 30px", marginTop: "10px" }}>
          {language === "ltr"
            ? "Products are unavailable"
            : "المنتجات غير متوفرة"}
        </div> : null
      }

      {isPageLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "10px 0",
          }}
        >
          <ClipLoader
            size={40}
            color={details?.vendor?.vendor_color}
          ></ClipLoader>
        </div>
      )}
      {cart?.cartCount ? <ReviewBar></ReviewBar> : null}
    </>
  );
}

export default ProductList;
