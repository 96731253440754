import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  AreaContext,
  AvaibilityContext,
  CartContext,
  InterNationalContext,
  LanguageContext,
  VendorContext,
  VendorSlugContext,
} from "../../../App";
import { API_URL } from "../../../services/constants";
import { LazyLoadImage } from "react-lazy-load-image-component";
import moment from "moment";
import { toast } from "react-toastify";
import ReactPixel from "react-facebook-pixel";
import SnapPixel from "react-snapchat-pixel";
import TiktokPixel from "tiktok-pixel";
import { addCartTag } from "../../../cartaddtag";
import sha256 from "sha256";
import Spinner from "../../../SVGs/Spinner";
import {
  addToCartApi,
  removeCartItem,
  updateCartQauntity,
} from "../../APIS/productApi";
import { triggerAddToCart } from "../../APIS/facebookApi";
import BrokenImage from "../../Common/BrokenImage";

function ProductItem({ item }) {
  const { cart, setCart } = useContext(CartContext);
  const { language, setLanguage } = useContext(LanguageContext);
  const { areaDetails } = useContext(AreaContext);
  const details = useContext(VendorContext);
  const history = useHistory();
  const vendorSlug = useContext(VendorSlugContext);
  const [inCart, setInCart] = useState(0);
  const { avaibility, setAvaibility } = useContext(AvaibilityContext);
  const [spinLoader, setSpinLoader] = useState(false);
  const { internationalDelivery } = useContext(InterNationalContext);

  const notify = (message, message_ar, language) =>
    toast.success(language == "ltr" ? message : message_ar);

  const onAddToCartClick = async (event, n) => {
    event.preventDefault();
    const temp = cart?.cartItems?.filter((k, i) => item?.id == k?.product_id);
    if (item?.add_ons_count?.cnt != 0 || item?.variation_count?.cnt != 0) {
      history.push(`/product=${item?.product_slug}`);
    } else if (n == -1 && inCart == 1) {
      setSpinLoader(true);
      const response = await removeCartItem({
        vendorSlug: vendorSlug,
        vendors_id: details?.vendor?.vendors_id,
        area_id: areaDetails?.area_id,
        user_string: localStorage.getItem("userID"),
        item_id: temp[0]?.item_id,
      });
      if (response?.status) {
        if (response.data.cartCount == 0) {
          setSpinLoader(false);
          setCart((cart) => { });
        } else {
          setSpinLoader(false);
          setCart(response.data);
        }
      }
    } else {
      if (inCart + n <= Number(item?.quantity)) {
        if (temp?.length === 0 || !temp?.length) {
          setSpinLoader(true);
          const response = await addToCartApi({
            vendorSlug: vendorSlug,
            vendors_id: details?.vendor?.vendors_id,
            area_id: areaDetails?.area_id,
            itemId: item?.id,
            user_string: localStorage.getItem("userID"),
            quantity: inCart + n,
            branch_id: 87,
            add_on_ids: [],
          });
          if (response?.status) {
            localStorage.setItem("cartTime", new Date());

            if (response.status == false) {
              notify(response.message, response.message_ar, language);
            }
            if (details?.vendor?.fb_pixel_code != "") {
              ReactPixel.track("AddToCart", {
                content_name: item?.product_name,
                content_category: item?.category_name,
                content_ids: [item?.id],
                content_type: "product",
                value: item?.product_price,
                currency: "KWD",
              });
              if (vendorSlug == "mijana-restaurant-and-café") {
                triggerAddToCart({
                  fb_pixel_code: details?.vendor?.fb_pixel_code,
                  fb_access_token:
                    "EAAGZA8GMZAs1IBAC9mDImnZCTAdafRzN769x6ZCIRMExueSZBZBfnDkIzGrsP4gZBMZCCwXaSvKNggZBEKdEk3582JWiwecrnZAEHFzfCaYKSNRbltxMm2cSvUrgZBUDpVNZCQAOVWUuzO4m7nbvQn1Wqb94IBbVSexSbwWzAf6TYV80HQF1ZAZAzGcXKB",
                  support_mail: details?.vendor?.support_mail,
                  item: item,
                });
              }
              if (vendorSlug == "butters") {
                triggerAddToCart({
                  fb_pixel_code: "546180060531909",
                  fb_access_token:
                    "EAAVDp1efPkMBOZBE2DPWrA7he9iJFn9EZBUpd4k3cRjpApcbNMLJgbdmelpI1uApMyxEYoorwBD5ZBDGPL5NWMxXGrKpoAHHxG9NtBMrppMm8YHLRmFgiYVL37nu7PUaO3WPfz4U4K75jIH7eErUZCSRAeJJyQpc88THHEBQGMozZBM9894dBoOe06gklfRtqZCgZDZD",
                  support_mail: details?.vendor_data?.support_mail,
                });
              }

              // dynamic for all vendors
              if (
                details?.vendor?.fb_access_token &&
                details?.vendor?.fb_access_token != ""
              ) {
                triggerAddToCart({
                  fb_pixel_code: details?.vendor?.fb_pixel_code,
                  fb_access_token: details?.vendor?.fb_access_token,
                  support_mail: details?.vendor?.support_mail,
                  item: item,
                });
              }
            }

            if (details?.vendor?.snap_pixel_code != "")
              SnapPixel.track("ADD_CART", {
                content_name: item?.product_name,
                item_category: item?.category_name,
                item_ids: [item?.id],
                content_type: "product",
                price: item?.product_price,
                currency: "KWD",
              });

            if (details?.vendor?.vendors_id === "132") {
              TiktokPixel.track("AddToCart", {
                content_type: "product",
                quantity: 1,
                content_name: item?.product_name,
                content_id: item?.id,
                currency: "KWD",
                value: item?.product_price,
              });
            }

            if (
              details?.vendor?.google_tag_code != "" &&
              !/^GTM/.test(details?.vendor?.google_tag_code)
            )
              addCartTag({
                item_id: item?.id,
                item_name: item?.product_name,
                currency: "KWD",
                discount: item?.discount_value,
                item_category: item?.category_name,
                price: item?.product_price,
                quantity: n,
              });

            setSpinLoader(false);
            setCart(response.data);
            if (
              (areaDetails?.type != "delivery" || areaDetails?.area == "") &&
              (areaDetails?.type != "pickup" || areaDetails?.branch == "") &&
              n == 1 &&
              details?.vendor?.home_page_type != "18" &&
              (details?.vendor?.international_delivery === "" ||
                details?.vendor?.international_delivery === "" ||
                internationalDelivery.country_name.toLowerCase() === "kuwait")
            ) {
              history.push(`/area`);
            }
          }
        } else {
          setSpinLoader(true);
          const response = await updateCartQauntity({
            vendorSlug: vendorSlug,
            vendors_id: details?.vendor?.vendors_id,
            area_id: areaDetails?.area_id,
            user_string: localStorage.getItem("userID"),
            quantity: inCart + n,
            branch_id: 87,
            item_id: temp[0]?.item_id,
          });
          if (response?.status) {
            localStorage.setItem("cartTime", new Date());
            if (response.status == false) {
              notify(response.message, response.message_ar, language);
            }
            if (details?.vendor?.fb_pixel_code != "" && n == 1) {
              ReactPixel.track("AddToCart", {
                content_name: item?.product_name,
                content_category: item?.category_name,
                content_ids: [item?.id],
                content_type: "product",
                value: item?.product_price,
                currency: "KWD",
              });

              const time = Date.now();
              const sec = Math.round(time / 1000);
              //static for mijana
              if (vendorSlug == "mijana-restaurant-and-café") {
                triggerAddToCart({
                  fb_pixel_code: details?.vendor?.fb_pixel_code,
                  fb_access_token:
                    "EAAGZA8GMZAs1IBAC9mDImnZCTAdafRzN769x6ZCIRMExueSZBZBfnDkIzGrsP4gZBMZCCwXaSvKNggZBEKdEk3582JWiwecrnZAEHFzfCaYKSNRbltxMm2cSvUrgZBUDpVNZCQAOVWUuzO4m7nbvQn1Wqb94IBbVSexSbwWzAf6TYV80HQF1ZAZAzGcXKB",
                  support_mail: details?.vendor?.support_mail,
                  item: item,
                });
              }
              if (vendorSlug == "butters") {
                triggerAddToCart({
                  fb_pixel_code: "546180060531909",
                  fb_access_token:
                    "EAAVDp1efPkMBOZBE2DPWrA7he9iJFn9EZBUpd4k3cRjpApcbNMLJgbdmelpI1uApMyxEYoorwBD5ZBDGPL5NWMxXGrKpoAHHxG9NtBMrppMm8YHLRmFgiYVL37nu7PUaO3WPfz4U4K75jIH7eErUZCSRAeJJyQpc88THHEBQGMozZBM9894dBoOe06gklfRtqZCgZDZD",
                  support_mail: details?.vendor_data?.support_mail,
                });
              }

              //dynamic for all vendors
              if (
                details?.vendor?.fb_access_token &&
                details?.vendor?.fb_access_token != ""
              ) {
                triggerAddToCart({
                  fb_pixel_code: details?.vendor?.fb_pixel_code,
                  fb_access_token: details?.vendor?.fb_access_token,
                  support_mail: details?.vendor?.support_mail,
                  item: item,
                });
              }
            }
            if (details?.vendor?.snap_pixel_code != "" && n == 1)
              SnapPixel.track("ADD_CART", {
                content_name: item?.product_name,
                item_category: item?.category_name,
                item_ids: [item?.id],
                content_type: "product",
                price: item?.product_price,
                currency: "KWD",
              });

            if (details?.vendor?.vendors_id === "132" && n == 1) {
              TiktokPixel.track("AddToCart", {
                content_type: "product",
                quantity: 1,
                content_name: item?.product_name,
                content_id: item?.id,
                currency: "KWD",
                value: item?.product_price,
              });
            }

            if (
              details?.vendor?.google_tag_code != "" &&
              !/^GTM/.test(details?.vendor?.google_tag_code) &&
              n == 1
            )
              addCartTag({
                item_id: item?.id,
                item_name: item?.product_name,
                currency: "KWD",
                discount: item?.discount_value,
                item_category: item?.category_name,
                price: item?.product_price,
                quantity: n,
              });
            setSpinLoader(false);
            setCart(response.data);
            if (
              (areaDetails?.type != "delivery" || areaDetails?.area == "") &&
              (areaDetails?.type != "pickup" || areaDetails?.branch == "") &&
              n == 1 &&
              (details?.vendor?.international_delivery === "3" ||
                details?.vendor?.international_delivery === "" ||
                internationalDelivery.country_name.toLowerCase() === "kuwait")
            ) {
              history.push(`/area`);
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    if (cart?.cartItems) {
      const temp = cart?.cartItems?.filter((k, i) => item?.id == k?.product_id);
      if (temp.length == 0) {
        setInCart(0);
      } else {
        let n = 0;
        temp?.forEach((k, i) => {
          n = n + parseInt(k?.quantity);
        });
        setInCart(n);
      }
    } else {
      setInCart(0);
    }
  }, [cart]);

  return (
    <li className={`products-list-item ${inCart != 0 ? "choose" : ""}`}>
      <Link to={`/product=${item?.product_slug}`} className="product-div">
        {inCart != 0 && <span className="item-count">{inCart}x</span>}
        <div>
          <Link
            className="product-image-link"
            to={`/product=${item?.product_slug}`}
          >
            {/* <LazyLoadImage
              style={{
                borderRadius:
                  details?.vendor?.home_page_type == "18" ? "0px" : "6px",
              }}
              src={
                item?.image
                  ? item?.image
                  : language === "ltr"
                  ? details?.vendor?.english_new_background
                  : details?.vendor?.arabic_new_background
              }
              className="product-list-image"
            ></LazyLoadImage> */}
            <BrokenImage
              style={{
                borderRadius:
                  details?.vendor?.home_page_type == "18" ? "0px" : "6px",
              }}
              src={item?.image}
              imgClass={"product-list-image"}
              fallbackSrc={
                language === "ltr"
                  ? details?.vendor?.english_new_background
                  : details?.vendor?.arabic_new_background
              }
            />
            {item?.label && (
              <span
                style={{
                  backgroundColor: item.label_color,
                  borderRadius:
                    details?.vendor?.home_page_type == "18" ? "0px" : "6px",
                }}
                className="product-offer"
              >
                {language == "ltr" ? item?.label : item?.label_ar}
              </span>
            )}
          </Link>
        </div>
        <div className="product-list-description">
          <Link
            className="product-list-title"
            to={`/product=${item?.product_slug}`}
          >
            {language === "ltr" ? item?.product_name : item?.product_name_ar}
          </Link>
          <p className="product-list-subtitle">
            {language === "ltr"
              ? item?.short_description
                ?.replace(/(<([^>]+)>)/gi, "")
                .replace(/\&nbsp;/gi, "")
                .replace(/\s\s+/g, " ")
                .replace(/&#39;/gi, "'")
              : item?.short_description_ar
                ?.replace(/(<([^>]+)>)/gi, "")
                .replace(/\&nbsp;/gi, "")
                .replace(/\s\s+/g, " ")
                .replace(/&#39;/gi, "'")}
          </p>
          {(areaDetails?.branchForArea?.start > moment() ||
            moment() > areaDetails?.branchForArea?.end ||
            !areaDetails?.data?.branch?.filter(
              (k) => k?.id == areaDetails?.branchForArea?.id
            )[0]?.on_shop_close_purchase !== "1") &&
            item?.product_type != 0 ? (
            <div className="product-cost-div">
              {/* <Link className="cost-bubble">Currently unavailable</Link> */}
              {item?.offer_applied == 1 && (
                <Link
                  onClick={(e) => e.preventDefault()}
                  className={`cost-bubble ${item?.product_status == 0 ? "small-padding" : ""
                    }`}
                  style={{
                    color: item?.color,
                    border: "none",
                    padding: 0,
                    fontSize: language == "ltr" ? 15 : 18,
                  }}
                >
                  {language === "ltr" ? item?.offer_msg : item?.offer_msg_ar}
                </Link>
              )}
              {item?.product_status == 0 ? (
                <Link
                  onClick={(e) => e.preventDefault()}
                  style={{
                    border: "none",
                    paddingRight: "0",
                    paddingLeft: "0",
                    color: "red",
                    fontSize: language == "ltr" ? 15 : 18,
                  }}
                  className={`cost-bubble price-bubble big-add-cart ${item?.offer_applied == 1 ? "small-padding" : ""
                    }`}
                >
                  {language === "ltr"
                    ? item?.status_label
                    : item?.status_label_ar}
                </Link>
              ) : item?.price_on_selection == 1 ? (
                <Link
                  to={`/product=${item?.product_slug}`}
                  style={{
                    border: "none",
                    paddingRight: "0",
                    paddingLeft: "0",
                    fontSize: language == "ltr" ? 15 : 18,
                  }}
                  className="cost-bubble price-bubble big-add-cart"
                >
                  {language === "ltr"
                    ? "Price On Selection"
                    : "السعر حسب الاختيار"}
                </Link>
              ) : item?.prodyct_type == 2 ? (
                <Link
                  to={`/product=${item?.product_slug}`}
                  className="buy-get-img "
                  style={{
                    borderRadius: "30px",
                    fontSize: language == "ltr" ? 12 : 15,
                    padding: "0 15px",
                    color: "#818181",
                    border: "2px solid #818181",
                    minWidth: "155px",
                  }}
                >
                  {language === "ltr"
                    ? "Product Registration only"
                    : "حجز المنتج فقط"}
                </Link>
              ) : inCart != 0 ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  className="price-bubble add-bubble"
                >
                  <span
                    className="cost-bubble"
                    style={{
                      border: "none",
                      margin: 0,
                      paddingRight: 0,
                      paddingLeft: 0,
                    }}
                  >
                    <span>
                      {parseFloat(item?.product_price).toFixed(3)}&nbsp;
                    </span>
                    {language === "rtl" ? "د.ك" : "KD"}
                  </span>
                  <Link
                    onClick={(e) => e.preventDefault()}
                    className="cost-bubble price-bubble big-add-cart"
                    style={{
                      marginTop: 0,
                      padding: "0 4px",
                      border: "none",
                      paddingLeft: 0,
                      paddingRight: 0,
                    }}
                  // style={{ border: "none", paddingLeft: 0, paddingRight: 0 }}
                  >
                    <div className="controlbuttondiv">
                      <button
                        className="control-button"
                        onClick={(e) => onAddToCartClick(e, -1)}
                      >
                        <i className="fa fa-minus"></i>
                      </button>
                      <Link
                        onClick={(e) => e.preventDefault()}
                        className="quantity-text"
                      >
                        {spinLoader ? (
                          <Spinner
                            height="16px"
                            size="2.5px"
                            color={details?.vendor?.vendor_color}
                          />
                        ) : (
                          inCart
                        )}
                      </Link>

                      <button
                        className="control-button"
                        onClick={(e) => onAddToCartClick(e, 1)}
                      >
                        <i className="fa fa-plus"></i>
                      </button>
                    </div>
                  </Link>
                </div>
              ) : (
                <Link
                  onClick={(e) => onAddToCartClick(e, 1)}
                  style={{
                    border: "none",
                    paddingLeft: 0,
                    paddingRight: 0,
                    display: "flex",
                    alignItems: "flex-end",
                  }}
                  className="cost-bubble price-bubble big-add-cart"
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    {item?.product_status == 1 &&
                      item?.discount_applied == 1 ? (
                      <del style={{ color: "red" }}>
                        <span
                          className="cost-bubble"
                          style={{
                            border: "none",
                            color: "red",
                            paddingRight: 0,
                            paddingLeft: 0,
                            padding: 0,
                            fontSize: language == "ltr" ? 12 : 15,
                            maxHeight: 17,
                            minHeight: 17,
                            margin: 0,
                          }}
                        >
                          <span>
                            {parseFloat(item?.base_price).toFixed(3)}&nbsp;
                          </span>
                          {language === "rtl" ? "د.ك" : "KD"}
                        </span>
                      </del>
                    ) : null}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingBottom: 3,
                      }}
                    >
                      <span>
                        {parseFloat(item?.product_price).toFixed(3)}&nbsp;
                      </span>
                      {language === "rtl" ? "د.ك" : "KD"}
                    </div>
                  </div>
                  <span
                    style={{
                      backgroundColor: details?.vendor?.vendor_color,
                    }}
                    className="cart-image-div"
                  >
                    {spinLoader ? (
                      <Spinner height="14px" size="2px" />
                    ) : (
                      <img
                        src={"pictures/Logo.png"}
                        className="cart-image-add"
                      ></img>
                    )}
                  </span>
                </Link>
              )}
            </div>
          ) : null}
        </div>
      </Link>
    </li>
  );
}

export default ProductItem;
