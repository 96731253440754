import React from 'react'

const BackIcon = () => {
    return (
        <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Group 266">
                <g id="Group 264">
                    <path id="Line 51" d="M1 6L6 11" stroke="black" strokeWidth="2" strokeLinecap="round" />
                    <path id="Line 52" d="M6 1L1 5.68477" stroke="black" strokeWidth="2" strokeLinecap="round" />
                </g>
            </g>
        </svg>
    )
}

export default BackIcon