import React from "react";
import ToggleCategoryList from "./components/ToggleCategoryList";

function ToggleCategory({ categories, categorynow, setcategorynow }) {
  return (
    <>
      {categories?.map((category, i) => (
        <ToggleCategoryList
          category={category}
          idx={i}
          categorynow={categorynow}
          setcategorynow={setcategorynow}
        ></ToggleCategoryList>
      ))}
    </>
  );
}

export default ToggleCategory;
