import axios from "axios";
import { API_URL, DELIVERY_API } from "../../services/constants";

export const getDeliveryCompanies = async ({
  vendor_id,
  product,
  distance,
  block_id,
  area_id,
  ecommerce_vendor_id,
  branch_id,
  destination,
  block,
  street,
  avenue,
  house_no,
  floor_no,
  flat_no,
  time,
  schedule_time,
  preorder_on
}) => {
  try {
    const response = await axios.post(
      `${DELIVERY_API}/get-estimated-delivery-time`,
      JSON.stringify({
        token: process.env.REACT_APP_TOKEN,
        vendor_id: vendor_id,
        ecommerce_vendor_id: ecommerce_vendor_id,
        // product: product,
        distance: distance,
        area_id: area_id,
        block_id: block_id,
        branch_id: branch_id,
        destination: destination,
        block: block,
        street: street,
        avenue: avenue,
        house_no: house_no,
        floor_no: floor_no,
        flat_no: flat_no,
        time: time,
        schedule_time: schedule_time,
        preorder_on: preorder_on
      })
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getCompanyOrderDetails = async ({
  vendorSlug,
  vendors_id,
  id,
  ecommerce_vendor_id,
}) => {
  try {
    const response = await axios.post(
      `${DELIVERY_API}/get-company-order-details`,
      JSON.stringify({
        token: process.env.REACT_APP_TOKEN,
        vendor_slug: vendorSlug,
        order_number: id,
        ecommerce_vendor_id: ecommerce_vendor_id,
      })
    );
    return response.data.data;
  } catch (error) {
    console.log(error);
  }
};

export const trackOrder = async (id) => {
  try {
    const response = await axios.post(
      `${DELIVERY_API}/get-tracking-status`,
      JSON.stringify({
        token: process.env.REACT_APP_TOKEN,
        tracking_number: id,
      })
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const applyReorder = async (
  order_number,
  is_proceed,
  user_string,
  vendor_slug,
  vendor_id
) => {
  try {
    const response = await axios.post(
      `${API_URL}/reorder`,
      JSON.stringify({
        token: process.env.REACT_APP_TOKEN,
        order_number: order_number,
        user_string: user_string,
        is_proceed: is_proceed,
        vendor_slug: vendor_slug,
        vendor_id: vendor_id,
      })
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
export const tryAgainOrder = async (vendorSlug, vendors_id, order_number) => {
  try {
    const response = await axios.post(
      `${API_URL}/order-tryagain`,
      JSON.stringify({
        token: process.env.REACT_APP_TOKEN,
        vendor_slug: vendorSlug,
        vendor_id: vendors_id,
        order_number: order_number,
        user_string: localStorage.getItem("userID"),
      })
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};


export const updateDeliveryCharges = async (vendorSlug, vendors_id, area_id, delivery_charge, successPromocode) => {
  try {
    const response = await axios.post(
      `${API_URL}/update-cart-delivery-charge`,
      JSON.stringify({
        token: process.env.REACT_APP_TOKEN,
        vendor_slug: vendorSlug,
        vendor_id: vendors_id,
        deliveryCharge: delivery_charge,
        area_id: area_id,
        user_string: localStorage.getItem("userID"),
        successPromocode: successPromocode
      })
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
}