import React, { useContext } from 'react'
import { LanguageContext } from '../../../App'
import CommonBackButton from './CommonBackButton'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import BackIcon from '../../../SVGs/BackIcon'

const CategoryDrawer = ({ handleCategory, details, setBurger }) => {
    const history = useHistory()
    const { language } = useContext(LanguageContext)
    const handleBack = () => {
        handleCategory()
    }
    return (
        <div className='sideMenu-mainDiv'>
            <CommonBackButton setBurger={setBurger} isBackButton={handleBack} />
            <div style={{ marginBottom: "51px" }}>
                <div className='section-header' >
                    <div>
                        {language === "ltr" ? "Sections" : "الأقسام"}
                    </div>
                </div>
                <div className='section-holder'>
                    {
                        details?.categories && details?.categories.map((section, i) =>
                            <div className='section-name-div' key={i} onClick={() => history.push(`/products=${section.category_slug}`)}>
                                <div className='section-name-text'>
                                    {language === "ltr" ? section.category_name : section.category_name_ar}
                                </div>
                                <div className='section-name-arrow'>
                                    <BackIcon />
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default CategoryDrawer