import React, { useContext } from "react";
import { LanguageContext } from "../../../App";

function SearchText() {
  const { language } = useContext(LanguageContext);
  return (
    <div className="search-text-div">
      <p className="search-big-text">
        {language === "ltr" ? "Search" : "البحث"}
      </p>
      <p className="search-small-text">
        {language === "ltr"
          ? "Type in what you are looking for"
          : "اكتب ما تبحث عنه"}
      </p>
    </div>
  );
}

export default SearchText;
