import React, { useContext, useEffect, useState } from 'react'
import ReactOwlCarousel from 'react-owl-carousel'
import { LanguageContext, VendorContext } from '../../../App'
import NewCommonNavbar from '../../NewCommonNavbar'
import NewSideBar from '../../NewSideBar'

const SideviewLayout = ({ component, isnavbar }) => {
    const [burger, setBurger] = useState(false)
    const details = useContext(VendorContext)
    const { language, setLanguage } = useContext(LanguageContext)
    const [customHeight, setCustomHeight] = useState(0)
    useEffect(() => {
        if (burger) {
            const div = document.getElementById("navbar-id")
            if (div) {
                const height = div.clientHeight
                setCustomHeight(height)
            }
        }
    }, [burger])
    return (
        <div className="container-fluid">
            <div className="row" style={burger && customHeight ? { height: customHeight, overflow: "hidden" } : {}}>
                <div className={`col-lg-4 col-md-12 order-md-2 order-sm-2 order-lg-1 order-2 small-col p-0 details-container bg-white `}>

                    {
                        isnavbar && <NewCommonNavbar
                            burger={burger}
                            setBurger={setBurger}
                            language={language}
                            setLanguage={setLanguage}
                            details={details}
                        />
                    }
                    {
                        burger && <NewSideBar setBurger={setBurger} />
                    }
                    {component}
                </div>
                <div
                    className={`col-lg-8 col-md-12 order-md-1 order-lg-2 order-sm-1 order-1 large-col p-0 none-res ${burger && customHeight ? "sideview-fixed" : ""}`}
                >
                    <div className="owl-bra">
                        <ReactOwlCarousel
                            dir="ltr"
                            items={1}
                            className="owl-theme"
                            loop={!(details?.vendor?.banner_images?.length == 1)}
                            mouseDrag={!(details?.vendor?.banner_images?.length == 1)}
                            autoplay={!(details?.vendor?.banner_images?.length == 1)}
                            dots={false}
                            animateIn="fadeIn"
                            animateOut="fadeOut"
                        >
                            {details?.vendor?.banner_images?.map((pic, i) => (
                                <div
                                    className={`${details?.vendor?.set_gradient_background == 1
                                        ? "gradient"
                                        : ""
                                        }`}
                                    key={i}
                                >
                                    <img className={`owl-banner-pic`} src={`${pic?.image}`} />
                                </div>
                            ))}
                        </ReactOwlCarousel>
                    </div>
                    <div className="logo-container">
                        {details?.vendor && details?.vendor?.english_new_background && (
                            <img
                                src={
                                    language == "ltr"
                                        ? details?.vendor?.english_new_background
                                        : details?.vendor?.arabic_new_background
                                }
                                className="img-fluid right-big-logo"
                                alt="payzah-logo"
                            ></img>
                        )}
                        <p className="logo-name mt-4">
                            {language == "ltr"
                                ? details?.vendor?.name
                                : details?.vendor?.name_ar}
                        </p>
                        <p className="logo-detail mt-2">
                            {language == "ltr"
                                ? details?.vendor?.slogan
                                : details?.vendor?.slogan_ar}
                        </p>
                    </div>
                </div>
            </div>
            {/* <WhatsappSticky></WhatsappSticky> */}
        </div >
    )
}

export default SideviewLayout