import React, { useContext, useEffect, useRef, useState } from "react";
import { InterNationalContext, LanguageContext } from "../../App";
// import { State, City } from "country-state-city";

const InternationalAddress = ({ internationalError }) => {
    const { internationalDelivery, setInternationalDelivery } = useContext(InterNationalContext);
    const [stateOption, setStateOption] = useState([]);
    const [cityOption, setCityOption] = useState([]);
    const { language } = useContext(LanguageContext);
    const [showoption, setShowoption] = useState(false);


    useEffect(() => {
        // if (internationalDelivery.delivery_country_code) {
        //     let state = State.getStatesOfCountry(internationalDelivery.delivery_country_code);
        //     setInternationalDelivery({
        //         ...internationalDelivery,
        //         delivery_state: "",
        //         delivery_state_code: "",
        //         delivery_city: "",
        //         delivery_city_code: "",
        //     })
        //     setStateOption(state)
        // }

    }, [internationalDelivery.delivery_country_code]);

    const handleState = (stateData) => {
        // if (stateData.state_name) {
        //     let city = City.getCitiesOfState(internationalDelivery.country_code, stateData.state_id)
        //     setCityOption(city)
        // }
        // setInternationalDelivery({
        //     ...internationalDelivery,
        //     delivery_state: stateData.state_name,
        //     delivery_state_code: stateData.state_id,
        //     delivery_city: "",
        //     delivery_city_code: "",
        // })
    }
    const handleCity = (cityData) => {

        setInternationalDelivery({
            ...internationalDelivery,
            delivery_city: cityData.city_name,
            delivery_city_code: cityData.city_id,
        })
        setShowoption(false)

    }

    const openDropDown = () => {
        setShowoption(!showoption)
    }
    return <div>
        <div className='contact-details-form-maindiv' style={{ marginTop: "20px" }}>
            <div className="contact-details-form-div" style={{ display: "flex", alignItems: "flex-end" }} >
                <div style={{ width: "100%" }}>
                    <div className='contact-form-container'>
                        <input
                            type="text"
                            className="contact-details-form-control"
                            placeholder=""
                            id="address1"
                            name="first_name"
                            required="true"
                            autoComplete
                            value={internationalDelivery.delivery_address1}
                            onChange={(e) => {
                                setInternationalDelivery({
                                    ...internationalDelivery,
                                    delivery_address1: e.target.value,
                                });
                            }}
                        ></input>
                        <label for="address1" className="contact-details-label-name contact-details-label-name1">
                            {language == "ltr" ? "Address 1" : "العنوان 1"}
                        </label>
                    </div>
                    {internationalError.delivery_address1 && (
                        <label className="error-text">
                            {language == "ltr"
                                ? "Please enter address 1"
                                : "الرجاء إدخال العنوان 1"}
                        </label>
                    )}
                </div>
            </div>
            <div className="contact-details-form-div" style={{ display: "flex", alignItems: "flex-end" }} >
                <div style={{ width: "100%" }}>
                    <div className='contact-form-container'>
                        <input
                            type="text"
                            className="contact-details-form-control"
                            placeholder=""
                            id="address2"
                            name="first_name"
                            required="true"
                            autoComplete
                            value={internationalDelivery.delivery_address2}
                            onChange={(e) => {
                                setInternationalDelivery({
                                    ...internationalDelivery,
                                    delivery_address2: e.target.value,
                                });
                            }}
                        ></input>
                        <label for="address2" className="contact-details-label-name contact-details-label-name1">
                            {language == "ltr" ? "Address 2" : "العنوان 2"}
                        </label>
                    </div>
                    {internationalError.delivery_address2 && (
                        <label className="error-text">
                            {language == "ltr"
                                ? "Please enter address 2"
                                : "الرجاء إدخال العنوان 2"}
                        </label>
                    )}
                </div>

            </div>
            <div className="contact-details-form-div" style={{ display: "flex", alignItems: "flex-end" }} >
                <div style={{ width: "100%" }}>
                    <div className='contact-form-container'>
                        <input
                            type="text"
                            className="contact-details-form-control"
                            placeholder=""
                            id="zipCode"
                            name="zip_code"
                            required="true"
                            autoComplete
                            value={internationalDelivery.delivery_zipCode}
                            onChange={(e) => {
                                setInternationalDelivery({
                                    ...internationalDelivery,
                                    delivery_zipCode: e.target.value,
                                });
                            }}
                        ></input>
                        <label for="zipCode" className="contact-details-label-name contact-details-label-name1">
                            {language == "ltr" ? "Zip Code" : "الرمز البريدي"}
                        </label>
                    </div>
                </div>
            </div>
            <div className="contact-details-form-div" style={{ display: "flex", alignItems: "flex-end" }} >
                <div style={{ width: "100%" }}>
                    <div className='contact-form-container'>
                        <input
                            type="text"
                            className="contact-details-form-control"
                            placeholder=""
                            id="delivery_specialInstruction"
                            name="Special Instructions"
                            required="true"
                            autoComplete
                            value={internationalDelivery.delivery_specialInstruction}
                            onChange={(e) => {
                                setInternationalDelivery({
                                    ...internationalDelivery,
                                    delivery_specialInstruction: e.target.value,
                                });
                            }}
                        ></input>
                        <label for="delivery_specialInstruction" className="contact-details-label-name contact-details-label-name1">
                            {language == "ltr" ? "Special Instructions" : "تعليمات خاصة"}
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>;
};

export default InternationalAddress;
