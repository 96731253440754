import axios from "axios";
import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import {
  BookingSetContext,
  LanguageContext,
  VendorContext,
} from "../../../App";
import "react-toastify/dist/ReactToastify.min.css";
import BookingCarousel from "./BookingCarousel";

function BookingPlace({
  booking,
  setStepper,
  setBookingDetails,
  bookingDetails,
  setTabOpen,
}) {
  const [note, setNote] = useState("");
  const details = useContext(VendorContext);
  const { language } = useContext(LanguageContext);
  const { setBookingSets } = useContext(BookingSetContext);

  const onNoteChange = (e) => setNote(e.target.value);

  return (
    <React.Fragment>
      <BookingCarousel booking={booking}></BookingCarousel>
      <div className="details-container pt-2">
        <div className="product-outer-div">
          <div className="product-inner-div">
            <h3 className="product-name">
              {language === "ltr" ? booking?.name : booking?.name_ar}
            </h3>
            <p className="product-category mt-3">
              {language === "ltr"
                ? booking.vendor_name
                : booking?.vendor_name_ar}
            </p>
          </div>
        </div>
      </div>
      {booking?.price != 0 ? (
        <div className="details-container pt-2">
          <div className="product-outer-div">
            <div className="product-inner-div">
              <div className="product-price-div">
                <p className="product-name">
                  {language === "ltr" ? "Price" : "السعر"}
                </p>
                <p className="product-price">
                  <span>
                    <>
                      <span>{parseFloat(booking.price).toFixed(3)}</span>{" "}
                      {language === "rtl" ? "د.ك" : "KD"}
                    </>{" "}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {booking?.description && (
        <div className="details-container pt-2">
          <div className="product-outer-div">
            <div className="product-inner-div">
              <div className="product-price-div">
                <p className="product-name">
                  {language == "ltr" ? "Description" : "وصف"}
                </p>
              </div>
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    language == "ltr"
                      ? booking?.description
                      : booking?.description_ar,
                }}
                className="product-category"
                style={{ fontWeight: "200 !important", marginTop: "10px" }}
              ></p>
            </div>
          </div>
        </div>
      )}

      {!true ? (
        <div className="details-container pt-2">
          <div className="product-outer-div">
            <div className="product-inner-div margin-bottom-div">
              <div className="product-notes-div">
                <label className="label-note">
                  {language == "ltr"
                    ? "Add a note (Optional)"
                    : "أضف ملاحظة (اختياري)"}
                </label>
                <input
                  type="text"
                  id="add_note"
                  value={note}
                  onChange={(e) => onNoteChange(e)}
                  aria-label=""
                  placeholder=""
                ></input>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div className="bottom-button">
        <a
          href={
            booking.google_map_url
              ? booking.google_map_url
              : `https://www.google.com/maps/search/?api=1&query=${booking.latitude}%2C${booking.longitude}`
          }
          target="_blank"
          className="text-center checkout-button"
          style={{
            width: "49%",
            backgroundColor: "white",
            border: `3px solid ${details?.vendor?.vendor_color}`,
            color: `${details?.vendor?.vendor_color}`,
          }}
        >
          {language == "ltr"
            ? `${details?.vendor?.place_label} Location`
            : `عرض على الخريط`}
        </a>
        <Link
          className="text-center checkout-button"
          style={{
            width: booking.latitude && booking.longitude ? "49%" : "100%",
          }}
          onClick={(e) => {
            e.preventDefault();
            setBookingDetails((book) => ({
              ...book,
              branch:
                details.vendor.home_page_type == 2 ||
                  details.vendor.home_page_type == 4
                  ? {
                    ...booking.k,
                    v_name: booking.k?.name,
                    v_name_ar: booking.k?.name_ar,
                    name: booking.k?.available_place[bookingDetails?.place]
                      ?.name,
                    name_ar:
                      booking.k?.available_place[bookingDetails?.place]
                        ?.name_ar,
                    payment_type:
                      booking.k?.available_place[bookingDetails?.place]
                        ?.payment_type,
                    total_amount:
                      booking.k?.available_place[bookingDetails?.place]
                        ?.total_amount,
                    amount:
                      booking.k?.available_place[bookingDetails?.place]
                        ?.amount,
                  }
                  : {
                    ...booking.k,
                    v_name: booking.k?.name,
                    v_name_ar: booking.k?.name_ar,
                    name: booking.k?.name,
                    name_ar: booking.k?.name_ar,
                    payment_type: booking.k?.payment_type,
                    total_amount: booking.k?.amount,
                    amount: booking.k?.amount,
                    enable_trainer: booking.enable_trainer,
                    trainer_cost: booking.trainer_cost,
                  },
            }));
            setBookingSets((sets) => ({
              ...sets,
              placeClicked: false,
              selectedPlace: {},
              max_slot:
                details.vendor.home_page_type == 1
                  ? booking.k.max_slot
                  : sets.max_slot,
              is_multi_slot:
                details.vendor.home_page_type == 1
                  ? booking.k.is_multi_slot
                  : sets.is_multi_slot,
            }));
            if (
              details.vendor.home_page_type == 2 ||
              details.vendor.home_page_type == 3
            )
              setStepper((set) => set + 1);
            else setTabOpen((tab) => tab + 1);
          }}
        >
          {details.vendor.home_page_type == 2 ||
            details.vendor.home_page_type == 3 ? (
            <>
              {`${language === "ltr" ? "Book" : "متابعة الحجز"}`}
              <span className="span-s">
                &nbsp;&nbsp;
                {parseFloat(booking?.price)?.toFixed(3)}
                &nbsp;
              </span>
              {`
              ${language === "rtl" ? "د.ك" : "KD"}`}
            </>
          ) : language === "ltr" ? (
            "Select"
          ) : (
            "إختر"
          )}
        </Link>
      </div>
    </React.Fragment>
  );
}

export default BookingPlace;
