import React, { useContext, useEffect, useState } from "react";
import {
  AreaContext,
  CartContext,
  ContactContext,
  InterNationalContext,
  LanguageContext,
  VendorContext,
  VendorSlugContext,
} from "../../App";
import CommonHeader from "./Components/CommonHeader";
import "./checkOut.css";
import "./rtlCheckout.css";
import NewContactDetails from "./Components/NewContactDetails";
import { Link, useHistory } from "react-router-dom";
import PickupContainer from "./Components/PickupContainer";
import moment from "moment";

const NewCheckOutPage = () => {
  const details = useContext(VendorContext);
  const [pickupError, setPickupError] = useState({
    modelError: "",
    colorError: "",
  });
  const { language } = useContext(LanguageContext);
  const [stepper, setStepper] = useState(0);
  const { areaDetails } = useContext(AreaContext);
  const history = useHistory();
  const { cart } = useContext(CartContext);
  const { contactDetails, setContactDetails } = useContext(ContactContext);
  const { internationalDelivery } = useContext(InterNationalContext);
  const vendorSlug = useContext(VendorSlugContext);

  const [errorContactDetails, setErrorContactDetails] = useState({
    emailError: false,
    emailErrorMessage: "",
    emailErrorMessagear: "",
    nameError: false,
    nameErrorMessage: "",
    nameErrorMessagear: "",
    phoneError: false,
    phoneErrorMessage: "",
    phoneErrorMessagear: "",
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!cart?.cartCount) {
      history.push(`/`);
    } else if (
      !areaDetails?.area &&
      !areaDetails?.branch &&
      details?.vendor?.home_page_type != "18" &&
      (details?.vendor?.international_delivery === "3" ||
        details?.vendor?.international_delivery === "") &&
      internationalDelivery.delivery_country_code.toLowerCase() === "kw"
    ) {
      history.push(`/area`);
    }
  }, []);

  const handleNext = () => {
    if (stepper === 0) {
      let email = emailValidation(contactDetails.email);
      let phone = phoneValidation(contactDetails.phone);
      let name = nameValidation(contactDetails.name);
      if (!email && !phone && !name) {
        let data = {
          email: contactDetails.email,
          phone: contactDetails.phone,
          name: contactDetails.name,
          code: contactDetails.phoneCode,
          expire: new Date().getTime(),
        };
        if (data) {
          localStorage.setItem("savedInfo", JSON.stringify(data));
        }
        if (areaDetails?.type === "pickup") {
          if (vendorSlug === "alawael-bilingual-school") {
            history.push("/checkout");
          }
          else {
            setStepper(1);
          }
        } else {
          history.push("/delivery-address");
        }
      }
    } else if (stepper === 1) {
      if (contactDetails.model !== "" && contactDetails.color !== "") {
        history.push("/checkout");
      } else {
        if (contactDetails.model == "" && contactDetails.color !== "") {
          setPickupError({ ...pickupError, modelError: true });
        } else if (contactDetails.model !== "" && contactDetails.color == "") {
          setPickupError({ ...pickupError, colorError: true });
        } else {
          setPickupError({
            ...pickupError,
            colorError: true,
            modelError: true,
          });
        }
      }
    }
  };

  const nameValidation = (value) => {
    let pattern =
      /^[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z ]+[\u0600-\u065F\u066A-\u06EF\u06FA-\u06FFa-zA-Z-_ ]*$/;
    if (value == "") {
      setErrorContactDetails((errorContactDetails) => ({
        ...errorContactDetails,
        nameErrorMessage: "This field is compulsory",
        nameErrorMessagear: "مطلوب ملء هذا الحقل",
        nameError: true,
      }));
      return true;
    } else if (value.match(pattern)) {
      setErrorContactDetails((errorContactDetails) => ({
        ...errorContactDetails,
        nameErrorMessage: "",
        nameError: false,
        nameErrorMessagear: "",
      }));
      return false;
    } else {
      setErrorContactDetails((errorContactDetails) => ({
        ...errorContactDetails,
        nameErrorMessage: "Only alphabets are allowed",
        nameError: true,
        nameErrorMessagear: "يسمح فقط الحروف الهجائية",
      }));
      return true;
    }
  };

  const phoneValidation = (value) => {
    let pattern = /^[0-9]+$/;
    let kwpattern = /^[124965]\d+$/;
    if (value === "") {
      setErrorContactDetails((errorContactDetails) => ({
        ...errorContactDetails,
        phoneErrorMessage: "This field is compulsory",
        phoneError: true,
        phoneErrorMessagear: "مطلوب ملء هذا الحقل",
      }));
      return true;
    } else if (value.length < 8) {
      setErrorContactDetails((errorContactDetails) => ({
        ...errorContactDetails,
        phoneErrorMessage: "Please enter at least 8 characters",
        phoneError: true,
        phoneErrorMessagear: "الرجاء إدخال 8 أرقام",
      }));
      return true;
    } else if (!value.match(pattern)) {
      setErrorContactDetails((errorContactDetails) => ({
        ...errorContactDetails,
        phoneErrorMessage: "Only numbers allowed",
        phoneError: true,
        phoneErrorMessagear: "مسموح بالأرقام فقط",
      }));
      return true;
    } else if (contactDetails?.phoneCode == "KW" && !value.match(kwpattern)) {
      setErrorContactDetails((errorContactDetails) => ({
        ...errorContactDetails,
        phoneErrorMessage: "Enter a valid phone number",
        phoneError: true,
        phoneErrorMessagear: "أدخل رقم هاتف صالح",
      }));
      return true;
    } else {
      setErrorContactDetails((errorContactDetails) => ({
        ...errorContactDetails,
        phoneErrorMessage: "",
        phoneError: false,
        phoneErrorMessagear: "",
      }));
      return false;
    }
  };

  const emailValidation = (value) => {
    let pattern =
      /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+(?:[A-Z]{2}|com|org|net|gov|mil|biz|info|mobi|name|aero|jobs|museum|co.in)\b/;
    if (
      value.match(pattern) ||
      (value === "" && details.vendor.vendors_id !== "1250")
    ) {
      setErrorContactDetails((errorContactDetails) => ({
        ...errorContactDetails,
        emailErrorMessage: "",
        emailError: false,
        emailErrorMessagear: "",
      }));
      return false;
    } else {
      setErrorContactDetails((errorContactDetails) => ({
        ...errorContactDetails,
        emailErrorMessage: "Enter a valid email",
        emailError: true,
        emailErrorMessagear: "البريد الالكتروني غير صحيح",
      }));
      return true;
    }
  };

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("savedInfo"))) {
      const savedInfo = JSON.parse(localStorage.getItem("savedInfo"));
      let diff = "";
      if (savedInfo.expire) {
        const currentTime = new Date().getTime();
        diff = currentTime - parseInt(savedInfo.expire, 10);
      }
      if (diff > 30 * 60 * 1000) {
        localStorage.removeItem("savedInfo");
      } else {
        setContactDetails({
          ...contactDetails,
          email: savedInfo.email,
          name: savedInfo.name,
          phone: savedInfo.phone,
          phoneCode: savedInfo.code,
        });
      }
    }
  }, []);

  return (
    <>
      <div className="holder-container">
        <div>
          <CommonHeader
            englishHeader="Checkout"
            arabicHeader="تفاصيل الإتصال"
          />
        </div>
        {stepper === 0 ? (
          <NewContactDetails
            contactDetails={contactDetails}
            setContactDetails={setContactDetails}
            errorContactDetails={errorContactDetails}
            setErrorContactDetails={setErrorContactDetails}
            nameValidation={nameValidation}
            phoneValidation={phoneValidation}
            emailValidation={emailValidation}
            internationalDelivery={internationalDelivery}
          />
        ) : null}

        {stepper === 1 ? (
          <PickupContainer
            contactDetails={contactDetails}
            setContactDetails={setContactDetails}
            pickupError={pickupError}
          />
        ) : null}
      </div>
      <div
        className={`contact-details-bottom-button contact-details-mobile-button ${details?.vendor?.home_page_type === "18" && "fashion-theme"
          }`}
      >
        <Link
          className="contact-details-back-button"
          onClick={(e) => {
            e.preventDefault();
            if (stepper === 0) {
              history.push("/review");
            } else if (stepper === 1) {
              setStepper(0);
            }
          }}
        >
          {language === "ltr" ? "Back" : "تراجع"}
        </Link>
        <Link className="contact-details-next-button" onClick={handleNext}>
          {language === "ltr" ? "Next" : "متابعة"}
        </Link>
      </div>
    </>
  );
};

export default NewCheckOutPage;
