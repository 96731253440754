import axios from "axios";
import moment from "moment";
import React, { useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  BookingSetContext,
  LanguageContext,
  VendorContext,
  VendorSlugContext,
} from "../../../App";
import "react-calendar/dist/Calendar.css";
import Calendar from "react-calendar";
import { useState } from "react";
import { BOOK_URL } from "../../../services/constants";
import $ from "jquery";
import { add } from "../../../api";

function BookingPlaceSearch({
  setStepper,
  bookingDetails,
  setBookingDetails,
  openCal,
  setOpenCal,
  tabOpen,
  setTabOpen,
}) {
  const vendorSlug = useContext(VendorSlugContext);
  const { language } = useContext(LanguageContext);
  const details = useContext(VendorContext);
  const { bookingSets, setBookingSets } = useContext(BookingSetContext);

  useEffect(() => {
    if (bookingDetails?.date)
      setTimeout(
        () =>
          onDateSelect(
            `date${moment(bookingDetails?.date)
              ?.locale("en")
              .format("DDMMYYYY")}`
          ),
        100
      );
  }, []);

  const onDateSelect = (k) => {
    $("#date-scroller").animate(
      {
        scrollLeft:
          $(`#${k}`)?.position()?.left +
          $(`#${k}`)?.width() / 2 +
          $("#date-scroller")?.scrollLeft() -
          $("#date-scroller")?.width() / 2,
      },
      "slow"
    );
  };

  const checkNext = () => {
    if (bookingDetails?.time?.length != 0) return true;
    else return false;
  };

  const onMinus = () => {
    if (bookingDetails?.attendee != 1) {
      setBookingDetails((a) => ({ ...a, attendee: a?.attendee - 1 }));
    }
  };

  const onPlus = () => {
    if (bookingDetails?.attendee != bookingSets?.maxAttend) {
      setBookingDetails((a) => ({ ...a, attendee: a?.attendee + 1 }));
    }
  };

  const sortTime = (i, j) => {
    return i?.slot_start_time < j?.slot_start_time ? -1 : 1;
  };
  const onTimeClick = (i) => {
    if (bookingSets?.is_multi_slot == 1 && bookingSets?.max_slot != 1) {
      let temp = bookingDetails?.time;
      if (
        !bookingDetails?.time?.some(
          (t) => t?.slot_start_time == i?.slot_start_time
        )
      ) {
        if (temp?.length < Number(bookingSets?.max_slot)) temp?.push(i);
      } else {
        temp = temp.filter((f) => f?.slot_start_time !== i?.slot_start_time);
      }
      temp = temp?.sort(sortTime);
      setBookingDetails((s) => ({
        ...s,
        time: temp,
      }));
    } else {
      let temp = bookingDetails?.time;
      if (
        !bookingDetails?.time?.some(
          (t) => t?.slot_start_time == i?.slot_start_time
        )
      ) {
        temp = [i];
      } else temp = [];
      setBookingDetails((s) => ({
        ...s,
        time: temp,
      }));
    }
  };

  const createTemp = (p) => {
    let temp = p;

    temp = temp.filter((place) => {
      let { booking_place_id, available_slot } = place;
      available_slot = Object.keys(available_slot)[0];

      return !bookingSets.cronBooked.some((cron) => {
        let isPlaceSame = cron.placeId == booking_place_id;
        let isDatesSame = cron.dates.includes(available_slot);

        let isTimeSame = cron.timeSlots.some((a) => {
          return bookingDetails.time.some(
            (b) =>
              a.slot_start_time == b.slot_start_time &&
              a.slot_end_time == b.slot_end_time
          );
        });

        return isPlaceSame && isDatesSame && isTimeSame;
      });
    });

    if (temp?.length != 0) {
      setBookingSets((set) => ({
        ...set,
        branchAvail: false,
        places: temp,
        base: p,
      }));
    } else {
      setBookingSets((set) => ({
        ...set,
        branchAvail: true,
        base: p,
        places: [],
      }));
    }
  };

  const onSearchClick = () => {
    axios
      .post(
        `${BOOK_URL}/search`,
        JSON.stringify({
          token: process.env.REACT_APP_TOKEN,
          vendor_slug: vendorSlug,
          booking_vendor_id: details?.vendor?.booking_vendor_id,
          booking_date: bookingDetails?.date?.locale("en").format("YYYY-MM-DD"),
          booking_term: "1",
          slot: bookingDetails?.time?.map((t) => ({
            slot_start_time: t?.slot_start_time,
            slot_end_time: t?.slot_end_time,
          })),
        })
      )
      .then((res) => {
        if (res.data.data.status) {
          let temp = Object.values(res.data.data.details)?.map((d) =>
            d.available_place.map((place) => ({
              ...place,
              enable_trainer: d.enable_trainer,
              trainer_cost: d.trainer_cost,
            }))
          );
          temp = [].concat.apply([], temp);
          if (bookingSets.cronBooked.length !== 0) createTemp(temp);
          else
            setBookingSets((set) => ({
              ...set,
              branchAvail: false,
              places: temp,
              base: temp,
            }));
        } else {
          setBookingSets((set) => ({
            ...set,
            branchAvail: true,
            base: [],
            places: [],
          }));
        }
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    if (bookingSets.base.length !== 0) {
      let temp = bookingSets?.base;
      createTemp(temp);
    }
  }, [bookingSets.cronBooked]);

  return (
    <>
      <div style={{ paddingBottom: 10 }}>
        <div
          className="checkout-step-one"
          style={{ paddingRight: 0, paddingLeft: 0 }}
        >
          {/* <h1
            className="main-heading"
            style={{ paddingRight: 16, paddingLeft: 16 }}
          >
            {language == "ltr" ? "Reservations" : "التحفظات"}
          </h1> */}
          <div className="checkout-one" style={{ backgroundColor: "#f5f5f9" }}>
            {tabOpen >= 3 ? (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "0px 16px 16px",
                    backgroundColor: "white",
                  }}
                >
                  <p
                    style={{
                      fontSize: language == "ltr" ? 20 : 23,
                      fontWeight: 600,
                    }}
                  >
                    {language == "ltr" ? "Booking Details" : "معلومات الحجز"}
                  </p>
                  <p
                    style={{
                      color: details?.vendor?.vendor_color,
                      fontSize: language == "ltr" ? 18 : 21,
                      fontWeight: 600,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setBookingSets((set) => ({
                        ...set,
                        booking_search: false,
                      }));
                      setTabOpen(() => 2);
                    }}
                  >
                    {language == "ltr" ? "Change" : "تغيير"}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0px 16px 16px",
                    backgroundColor: "white",
                    borderBottom: "8px solid #f6f7fb",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <p
                      style={{
                        display: "flex",
                      }}
                    >
                      <i
                        style={{
                          fontSize: 18,
                        }}
                        className="far fa-calendar"
                      ></i>
                      &nbsp;&nbsp;
                      <p
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexGrow: 1,
                        }}
                      >
                        <span
                          style={{
                            fontSize: language == "ltr" ? 16 : 19,
                            fontWeight: 600,
                          }}
                        >
                          {language == "ltr" ? "Date" : "التاريخ"}
                        </span>
                        <span
                          style={{
                            fontSize: language == "ltr" ? 15 : 18,
                            fontWeight: 600,
                            color: "gray",
                          }}
                          id="block-addr"
                        >
                          {language == "ltr" ? (
                            bookingDetails?.date?.format("D MMM YYYY")
                          ) : (
                            <>
                              <span
                                style={{ fontSize: 18 }}
                                className="number-span"
                              >
                                {bookingDetails?.date?.format("D")}
                              </span>{" "}
                              {moment(bookingDetails?.date)
                                ?.locale("ar")
                                ?.format("MMM")}{" "}
                              <span
                                style={{ fontSize: 18 }}
                                className="number-span"
                              >
                                {bookingDetails?.date?.format("YYYY")}
                              </span>
                            </>
                          )}
                        </span>
                      </p>
                    </p>
                  </div>
                  <div style={{ width: "100%", marginTop: 15 }}>
                    <p style={{ display: "flex" }}>
                      <i
                        style={{
                          fontSize: 18,
                        }}
                        className="far fa-clock"
                      ></i>
                      &nbsp;&nbsp;
                      <p
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexGrow: 1,
                        }}
                      >
                        <span
                          style={{
                            fontSize: language == "ltr" ? 16 : 19,
                            fontWeight: 600,
                          }}
                        >
                          {language == "ltr" ? "Time" : "الوقت"}
                        </span>
                        <span
                          style={{
                            fontSize: language == "ltr" ? 15 : 18,
                            fontWeight: 600,
                            color: "gray",
                          }}
                          id="block-addr"
                        >
                          {bookingDetails?.time?.map((i, k) => (
                            <>
                              {k == 0 ? (
                                ""
                              ) : (
                                <>
                                  , <br></br>
                                </>
                              )}
                              <span
                                style={{ fontSize: 15 }}
                                className="number-span"
                              >
                                {i.start_time}
                              </span>{" "}
                              {language == "ltr"
                                ? i?.start_time_meridiem
                                : i?.start_time_meridiem_ar}
                              {" - "}
                              <span
                                style={{ fontSize: 15 }}
                                className="number-span"
                              >
                                {i.end_time}
                              </span>{" "}
                              {language == "ltr"
                                ? i?.end_time_meridiem
                                : i?.end_time_meridiem_ar}
                            </>
                          ))}
                        </span>
                      </p>
                    </p>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="booking-single-div">
                  <div className="booking-flex-div">
                    <p className="booking-sub-title">
                      {language == "ltr" ? "Date" : "التاريخ"}
                    </p>
                    <p className="booking-info-text">
                      {language == "ltr" ? (
                        bookingDetails?.date?.format("D MMM YYYY")
                      ) : (
                        <>
                          <span
                            style={{ fontSize: 18 }}
                            className="number-span"
                          >
                            {bookingDetails?.date?.format("D")}
                          </span>{" "}
                          {moment(bookingDetails?.date)
                            ?.locale("ar")
                            ?.format("MMM")}{" "}
                          <span
                            style={{ fontSize: 18 }}
                            className="number-span"
                          >
                            {bookingDetails?.date?.format("YYYY")}
                          </span>
                        </>
                      )}
                    </p>
                  </div>
                  <div className="pt-3">
                    <p
                      className="booking-info-text open-calendar"
                      onClick={() => setOpenCal((a) => !a)}
                    >
                      {!openCal
                        ? language == "ltr"
                          ? "Open Calendar"
                          : "افتح التقويم"
                        : language == "ltr"
                          ? "Close Calendar"
                          : "اغلاق الرزنامة"}
                    </p>
                    <div
                      style={openCal ? {} : { display: "none" }}
                      className="booking-calendar-container"
                    >
                      <Calendar
                        calendarType="Arabic"
                        minDetail="year"
                        onChange={(e) => {
                          setBookingDetails((a) => ({
                            ...a,
                            date: moment(e)?.locale("en"),
                          }));
                          setOpenCal((a) => !a);
                          setTabOpen((a) => 2);
                          setTimeout(() => {
                            onDateSelect(
                              `date${moment(e).locale("en").format("DDMMYYYY")}`
                            );
                          }, 100);
                        }}
                        formatDay={(l, d) => {
                          return moment(d)?.locale("en")?.format("D");
                        }}
                        formatMonthYear={(l, d) => {
                          return `${moment(d)
                            ?.locale(l)
                            ?.format("MMMM")} ${moment(d)
                              ?.locale("en")
                              ?.format("YYYY")}`;
                        }}
                        formatYear={(l, d) => {
                          return moment(d)?.locale("en")?.format("YYYY");
                        }}
                        value={bookingDetails?.date?.toDate()}
                        minDate={bookingSets?.dateNow?.toDate()}
                        maxDate={moment(bookingSets?.dateNow)
                          ?.add(
                            Number(bookingSets.max_date_selection) - 1,
                            "days"
                          )
                          .toDate()}
                        // maxDate={moment("31-01-2022", "DD-MM-YYYY").toDate()}
                        locale={language == "ltr" ? "en" : "ar"}
                      />
                    </div>
                    <div
                      style={!openCal ? {} : { display: "none" }}
                      className="date-scroller"
                      id="date-scroller"
                    >
                      {[...Array(2)].map((k, i) =>
                        moment(bookingSets?.dateNow)
                          ?.startOf("day")
                          .locale("en")
                          .subtract(2 - i, "days") >=
                          moment(bookingSets?.dateNow)?.startOf("day") ? (
                          <span
                            id={`date${moment(bookingSets?.dateNow)
                              .locale("en")
                              .subtract(2 - i, "days")
                              .format("DDMMYYYY")}`}
                            onClick={() => {
                              setBookingDetails((book) => ({
                                ...book,
                                time: [],
                                time_ar: [],
                                branch: "",
                                date: moment(bookingSets?.dateNow)
                                  .locale("en")
                                  .subtract(2 - i, "days"),
                              }));
                              setTabOpen((t) => 2);
                              onDateSelect(
                                `date${moment(bookingSets?.dateNow)
                                  .locale("en")
                                  .subtract(2 - i, "days")
                                  .format("DDMMYYYY")}`
                              );
                            }}
                            className={`date-selector ${moment(bookingSets?.dateNow)
                              .locale("en")
                              .subtract(2 - i, "days")
                              ?.format("D MMM YYYY") ==
                              bookingDetails?.date?.format("D MMM YYYY")
                              ?
                              "booking-slot-active"
                              : ""
                              }`}
                          >
                            <span
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <span>
                                {moment(bookingSets?.dateNow)
                                  .locale(language == "ltr" ? "en" : "ar")
                                  .subtract(2 - i, "days")
                                  ?.format("ddd")}
                              </span>

                              {language == "ltr" ? (
                                <span>
                                  {moment(bookingSets?.dateNow)
                                    .locale("en")
                                    .subtract(2 - i, "days")
                                    ?.format("D MMM YYYY")}
                                </span>
                              ) : (
                                <span>
                                  <span
                                    style={{ fontSize: 13 }}
                                    className="number-span"
                                  >
                                    {moment(bookingSets?.dateNow)
                                      .locale("en")
                                      .subtract(2 - i, "days")
                                      ?.format("D")}
                                  </span>{" "}
                                  {moment(bookingSets?.dateNow)
                                    ?.locale("ar")
                                    ?.subtract(2 - i, "days")
                                    ?.format("MMM")}{" "}
                                  <span
                                    style={{ fontSize: 13 }}
                                    className="number-span"
                                  >
                                    {moment(bookingSets?.dateNow)
                                      .locale("en")
                                      .subtract(2 - i, "days")
                                      ?.format("YYYY")}
                                  </span>
                                </span>
                              )}
                            </span>
                          </span>
                        ) : null
                      )}
                      {[
                        ...Array(
                          Number(bookingSets.max_date_selection)
                          // 30
                        ),
                      ].map((k, i) => (
                        <span
                          id={`date${moment(bookingSets?.dateNow)
                            .locale("en")
                            .add(i, "days")
                            .format("DDMMYYYY")}`}
                          onClick={() => {
                            setBookingDetails((book) => ({
                              ...book,
                              time: [],
                              time_ar: [],
                              branch: "",
                              date: moment(bookingSets?.dateNow)
                                .locale("en")
                                .add(i, "days"),
                            }));
                            setTabOpen((t) => 2);
                            onDateSelect(
                              `date${moment(bookingSets?.dateNow)
                                .locale("en")
                                .add(i, "days")
                                .format("DDMMYYYY")}`
                            );
                          }}
                          className={`date-selector ${moment(bookingSets?.dateNow)
                            .locale("en")
                            .add(i, "days")
                            ?.format("D MMM YYYY") ==
                            bookingDetails?.date?.format("D MMM YYYY")
                            ?
                            "booking-slot-active"
                            : ""
                            }`}
                        >
                          <span
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <span>
                              {moment(bookingSets?.dateNow)
                                .locale(language == "ltr" ? "en" : "ar")
                                .add(i, "days")
                                ?.format("ddd")}
                            </span>

                            {language == "ltr" ? (
                              <span>
                                {moment(bookingSets?.dateNow)
                                  .locale("en")
                                  .add(i, "days")
                                  ?.format("D MMM YYYY")}
                              </span>
                            ) : (
                              <span>
                                <span
                                  style={{ fontSize: 13 }}
                                  className="number-span"
                                >
                                  {moment(bookingSets?.dateNow)
                                    .locale("en")
                                    .add(i, "days")
                                    ?.format("D")}
                                </span>{" "}
                                {moment(bookingSets?.dateNow)
                                  ?.locale("ar")
                                  ?.add(i, "days")
                                  ?.format("MMM")}{" "}
                                <span
                                  style={{ fontSize: 13 }}
                                  className="number-span"
                                >
                                  {moment(bookingSets?.dateNow)
                                    .locale("en")
                                    .add(i, "days")
                                    ?.format("YYYY")}
                                </span>
                              </span>
                            )}
                          </span>
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="booking-single-div">
                  <div className="booking-flex-div">
                    <p className="booking-sub-title">
                      {language == "ltr" ? "Time" : "وقت الحجز"}
                    </p>
                    <p className="booking-info-text">
                      {
                        bookingDetails?.time?.length == 0
                          ? language == "ltr"
                            ? "Any"
                            : "إختر الوقت"
                          : bookingDetails?.time?.map((i, k) => (
                            <>
                              {k == 0 ? "" : ", "}
                              <span
                                style={{ fontSize: 18 }}
                                className="number-span"
                              >
                                {i.start_time}
                              </span>{" "}
                              {language == "ltr"
                                ? i?.start_time_meridiem
                                : i?.start_time_meridiem_ar}
                            </>
                          ))
                      }
                    </p>
                  </div>
                  <div
                    className="pt-3"
                    style={!openCal && tabOpen >= 2 ? {} : { display: "none" }}
                  >
                    <div className="booking-time-div">
                      {bookingSets?.morning?.length != 0 &&
                        (moment()?.locale("en")?.format("DDMMYYYY") ==
                          moment(bookingDetails?.date)
                            ?.locale("en")
                            ?.format("DDMMYYYY")
                          ? moment() <=
                          moment(
                            bookingSets?.morning?.slice(-1)[0]
                              ?.slot_start_time,
                            "HH:mm:ss"
                          )
                          : true) && (
                          <div className="booking-time-period">
                            <p
                              className="booking-time-text"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {language == "ltr" ? "☀️ Morning" : "صباحاً ☀️"}
                            </p>
                            <div className="booking-time-slot-div">
                              {bookingSets?.morning?.map((i, k) => (
                                <>
                                  {(moment()
                                    ?.locale("en")
                                    ?.format("DDMMYYYY") ==
                                    moment(bookingDetails?.date)
                                      ?.locale("en")
                                      ?.format("DDMMYYYY")
                                    ? moment() <
                                    moment(i?.slot_start_time, "HH:mm:ss")
                                    : true) && (
                                      <button
                                        onClick={() => {
                                          onTimeClick(i);
                                          setTabOpen(() => 2);
                                        }}
                                        className={`booking-time-slot ${bookingDetails?.time?.some(
                                          (t) =>
                                            t?.slot_start_time ==
                                            i?.slot_start_time
                                        )
                                          ? "booking-slot-active"
                                          : ""
                                          } 
                            ${bookingSets?.is_multi_slot == 1 &&
                                            bookingSets?.max_slot != 1
                                            ? bookingDetails?.time?.length ==
                                              bookingSets?.max_slot &&
                                              !bookingDetails?.time?.some(
                                                (t) =>
                                                  t?.slot_start_time == i?.slot_start_time
                                              )
                                              ? "booking-time-booked"
                                              : ""
                                            : ""
                                          }
                            `}
                                      >
                                        <span
                                          style={{ fontSize: 12 }}
                                          className="number-span"
                                        >
                                          {i.start_time}
                                        </span>{" "}
                                        {language == "ltr"
                                          ? i?.start_time_meridiem
                                          : i?.start_time_meridiem_ar}{" "}
                                        -{" "}
                                        <span
                                          style={{ fontSize: 12 }}
                                          className="number-span"
                                        >
                                          {i.end_time}
                                        </span>{" "}
                                        {language == "ltr"
                                          ? i?.end_time_meridiem
                                          : i?.end_time_meridiem_ar}
                                      </button>
                                    )}
                                </>
                              ))}
                            </div>
                          </div>
                        )}
                      {bookingSets?.evening?.length != 0 && (
                        <div
                          className="booking-time-period"
                          style={{
                            paddingTop: (
                              moment()?.locale("en")?.format("DDMMYYYY") ==
                                moment(bookingDetails?.date)
                                  ?.locale("en")
                                  ?.format("DDMMYYYY")
                                ? moment() <=
                                moment(
                                  bookingSets?.morning?.slice(-1)[0]
                                    ?.slot_start_time,
                                  "HH:mm:ss"
                                )
                                : true
                            )
                              ? 20
                              : 0,
                          }}
                        >
                          <p
                            className="booking-time-text"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {/* <img src="pictures/icons8-moon-64.png" style={{ width: 30, height: 30, objectFit: "cover", objectPosition: "center" }}></img> */}
                            {language == "ltr" ? "🌙 Evening" : "مساءً 🌙"}
                          </p>
                          <div className="booking-time-slot-div">
                            {bookingSets?.evening?.map((i, k) => (
                              <>
                                {(moment()?.locale("en")?.format("DDMMYYYY") ==
                                  moment(bookingDetails?.date)
                                    ?.locale("en")
                                    ?.format("DDMMYYYY")
                                  ? moment() <
                                  moment(i?.slot_start_time, "HH:mm:ss")
                                  : true) && (
                                    <button
                                      onClick={() => {
                                        onTimeClick(i);
                                        setTabOpen(() => 2);
                                      }}
                                      className={`booking-time-slot ${bookingDetails?.time?.some(
                                        (t) =>
                                          t?.slot_start_time ==
                                          i?.slot_start_time
                                      )
                                        ? "booking-slot-active"
                                        : ""
                                        } ${bookingSets?.is_multi_slot == 1 &&
                                          bookingSets?.max_slot != 1
                                          ? bookingDetails?.time?.length ==
                                            bookingSets?.max_slot &&
                                            !bookingDetails?.time?.some(
                                              (t) =>
                                                t?.slot_start_time ==
                                                i?.slot_start_time
                                            )
                                            ? "booking-time-booked"
                                            : ""
                                          : ""
                                        }`}
                                    >
                                      <span
                                        style={{ fontSize: 12 }}
                                        className="number-span"
                                      >
                                        {i.start_time}
                                      </span>{" "}
                                      {language == "ltr"
                                        ? i?.start_time_meridiem
                                        : i?.start_time_meridiem_ar}{" "}
                                      -{" "}
                                      <span
                                        style={{ fontSize: 12 }}
                                        className="number-span"
                                      >
                                        {i.end_time}
                                      </span>{" "}
                                      {language == "ltr"
                                        ? i?.end_time_meridiem
                                        : i?.end_time_meridiem_ar}
                                    </button>
                                  )}
                              </>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}

            {tabOpen < 3 ? null : (
              <div className="booking-single-div">
                <div className="booking-flex-div">
                  <p className="booking-sub-title">
                    {language == "ltr"
                      ? details?.vendor?.place_label
                      : details?.vendor?.place_label_ar}
                  </p>
                  <p className="booking-info-text">
                    {bookingDetails?.branch == ""
                      ? language == "ltr"
                        ? "All"
                        : "إختر المكان"
                      : language == "ltr"
                        ? bookingDetails?.branch?.name
                        : bookingDetails?.branch?.name_ar}
                  </p>
                </div>
                <div
                  className="pt-3"
                  style={!openCal && tabOpen >= 3 ? {} : { display: "none" }}
                >
                  {bookingSets?.branchAvail ? (
                    <div
                      style={{
                        padding: "0px 10px",
                        color: details?.vendor?.vendor_color,
                        fontSize: 18,
                        textAlign: "center",
                      }}
                    >
                      {language == "ltr"
                        ? `No ${details?.vendor?.place_label} Available. Please Choose Another Date or Time`
                        : `لا ${details?.vendor?.place_label_ar} متاحة. الرجاء اختيار تاريخ أو وقت آخر`}
                    </div>
                  ) : (
                    <>
                      <ul
                        style={tabOpen == 3 ? {} : { display: "none" }}
                        className="booking-branch-list"
                      >
                        {bookingSets?.places?.map((k) => (
                          <li
                            onClick={() => {
                              add({
                                vendorId: details.vendor.booking_vendor_id,
                                userId: localStorage.getItem("userID"),
                                dates: [
                                  bookingDetails?.date?.format("YYYY-MM-DD"),
                                ],
                                timeSlots: bookingDetails?.time?.map((t) => ({
                                  slot_start_time: t?.slot_start_time,
                                  slot_end_time: t?.slot_end_time,
                                })),
                                placeId: k?.booking_place_id,
                                mainVendor: k?.booking_vendor_id,
                              });
                              setBookingSets((sets) => ({
                                ...sets,
                                selectedPlace: {
                                  name: k?.name,
                                  name_ar: k?.name_ar,
                                  description: k?.description,
                                  description_ar: k?.description_ar,
                                  unit_price: k?.amount,
                                  price: k?.total_amount,
                                  image: k?.place_image,
                                  google_map_link: k?.google_map_url,
                                  latitude: k?.latitude,
                                  longitude: k?.longitude,
                                  enable_trainer: k?.enable_trainer,
                                  trainer_cost: k?.trainer_cost,
                                  mainVendor: k?.booking_vendor_id,
                                  k,
                                },
                                placeClicked: true,
                              }));
                            }}
                            className="booking-branch"
                          >
                            <img
                              className="booking-branch-img"
                              src={k?.place_image}
                            ></img>
                            <div className="booking-branch-details">
                              <div className="booking-branch-title">
                                {language == "ltr" ? k?.name : k?.name_ar}
                              </div>
                              <div className="booking-branch-desc">
                                {language == "ltr"
                                  ? k?.description
                                  : k?.description_ar}
                              </div>
                              <div className={`booking-branch-button`}>
                                <button
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.nativeEvent.stopPropagation();
                                    setBookingDetails((book) => ({
                                      ...book,
                                      branch: {
                                        ...k,
                                      },
                                    }));
                                    add({
                                      vendorId:
                                        details.vendor.booking_vendor_id,
                                      userId: localStorage.getItem("userID"),
                                      dates: [
                                        bookingDetails?.date?.format(
                                          "YYYY-MM-DD"
                                        ),
                                      ],
                                      timeSlots: bookingDetails?.time?.map(
                                        (t) => ({
                                          slot_start_time: t?.slot_start_time,
                                          slot_end_time: t?.slot_end_time,
                                        })
                                      ),
                                      placeId: k?.booking_place_id,
                                      mainVendor: k?.booking_vendor_id,
                                    });
                                    setStepper((step) => step + 1);
                                  }}
                                >
                                  <span>
                                    {parseFloat(k?.total_amount)
                                      .toFixed(3)
                                      .toLocaleString("en")}
                                    &nbsp;
                                  </span>
                                  {language === "rtl" ? "د.ك" : "KD"}
                                  <img
                                    src={"pictures/Logo.png"}
                                    className="cart-image-add"
                                  ></img>
                                </button>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                      <div
                        style={
                          tabOpen > 3
                            ? { padding: "0px 0px 15px" }
                            : { display: "none" }
                        }
                        className="date-scroller"
                      >
                        {bookingSets?.places?.map((k) => (
                          <span
                            style={{
                              width: 165,
                              maxWidth: 165,
                              padding: "10px 0",
                              cursor: "pointer",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            onClick={() => {
                              setBookingDetails((book) => ({
                                ...book,
                                branch: {
                                  ...k,
                                },
                              }));
                            }}
                            className={`date-selector ${k?.vendors_id ==
                              bookingDetails?.branch?.vendors_id
                              ? "booking-slot-active"
                              : ""
                              }`}
                          >
                            {language == "ltr" ? k?.name : k?.name_ar}
                          </span>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}

          </div>
        </div>
      </div>
      {tabOpen < 3
        ? checkNext() && (
          <div
            className="bottom-button iphone-issue"
            style={
              {}
            }
          >
            <Link
              onClick={(e) => {
                e.preventDefault();
                if (checkNext()) {
                  onSearchClick();
                  setBookingDetails((book) => ({
                    ...book,
                    branch: "",
                    place: 0,
                  }));
                  setBookingSets((set) => ({
                    ...set,
                    places: [],
                    booking_search: true,
                  }));
                  setTabOpen(() => 3);
                }
              }}
              className="text-center checkout-button "
            >
              {checkNext()
                ? language == "ltr"
                  ? `Search`
                  : "بحث"
                : language == "ltr"
                  ? "Select all the fields"
                  : "حدد جميع الحقول"}
            </Link>
          </div>
        )
        : checkNext() && (
          <div
            className="bottom-button iphone-issue"
            style={
              {}
            }
          >
            <Link
              onClick={(e) => {
                e.preventDefault();
                setTabOpen(() => 2);
                setBookingSets((set) => ({
                  ...set,
                  booking_search: false,
                  branchAvail: false,
                }));
              }}
              className="text-center checkout-button"
              style={{
                backgroundColor: "white",
                border: `3px solid ${details?.vendor?.vendor_color}`,
                color: `${details?.vendor?.vendor_color}`,
              }}
            >
              {language == "ltr" ? "Back" : "تراجع"}
            </Link>
          </div>
        )}
    </>
  );
}

export default BookingPlaceSearch;
