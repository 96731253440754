import axios from "axios";
import moment, { lang } from "moment";
import React, { useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  AreaContext,
  LanguageContext,
  VendorContext,
  VendorSlugContext,
} from "../../../App";
import "react-calendar/dist/Calendar.css";
import Calendar from "react-calendar";
import { useState } from "react";
import { BOOK_URL } from "../../../services/constants";

function BranchBooking({
  setStepper,
  stepper,
  bookingDetails,
  setBookingDetails,
  bookingSets,
  setBookingSets,
}) {
  const history = useHistory();
  const vendorSlug = useContext(VendorSlugContext);
  const { language } = useContext(LanguageContext);
  const { areaDetails } = useContext(AreaContext);
  const details = useContext(VendorContext);
  const [tabOpen, setTabOpen] = useState(1);
  const [contUnavail, setContUnavail] = useState([]);
  const [openCal, setOpenCal] = useState(true);

  const checkNext = () => {
    if (bookingDetails?.branch?.name && bookingDetails?.time?.length != 0)
      return true;
    else return false;
  };

  const onMinus = () => {
    if (bookingDetails?.attendee != 1) {
      setBookingDetails((a) => ({ ...a, attendee: a?.attendee - 1 }));
    }
  };

  const onPlus = () => {
    if (bookingDetails?.attendee != bookingSets?.maxAttend) {
      setBookingDetails((a) => ({ ...a, attendee: a?.attendee + 1 }));
    }
  };


  useEffect(() => {
    if (bookingDetails?.branch != "") {
      let branch = bookingSets?.branches?.filter(
        (k) => k?.id == bookingDetails?.branch?.id
      )[0];
      let l = [];
      let total = branch?.slot_time + branch?.buffer_time;
      let temp = bookingDetails?.time?.map((i) =>
        moment(new Date(`01-01-2012, ${i}`))?.locale("en")
      );
      if (temp?.length != branch?.max && temp?.length != 0) {
        let o = moment(temp[0]);
        let p = moment(temp[temp?.length - 1]);
        let a = true;
        let b = true;
        for (let i = 0; i < branch?.max - temp?.length; i++) {
          if (
            branch?.booked_time_slot?.includes(
              p?.add(total, "minutes")?.format("HH:mm:ss")
            )
          )
            a = false;
          if (
            branch?.booked_time_slot?.includes(
              o?.subtract(total, "minutes")?.format("HH:mm:ss")
            )
          )
            b = false;
          if (a) l?.push(p?.format("hh:mm a"));
          if (b) l?.push(o?.format("hh:mm a"));
        }
        console.log(l);
      }
      setContUnavail(l);
    }
  }, [bookingDetails]);

  const sortTime = (i, j) => {
    i = new Date(`01-01-2012, ${i}`);
    j = new Date(`01-01-2012, ${j}`);
    i = moment(i);
    j = moment(j);
    return i?.format("HH:mm") < j?.format("HH:mm") ? -1 : 1;
  };

  const onTimeClick = (i, cont, max, timeSlot, buffer, b) => {
    if (cont) {
      let temp =
        b?.id == bookingDetails?.branch?.id ? bookingDetails?.time : [];
      if (!temp?.includes(i)) {
        if (temp?.length < max) temp?.push(i);
      } else {
        temp = temp.filter((f) => f !== i);
      }
      temp = temp?.sort(sortTime);
      setBookingDetails((s) => ({
        ...s,
        branch: temp?.length == 0 ? "" : b,
        time: temp,
        time_ar: temp?.map(
          (k) =>
            `${moment(new Date(`01-01-2012, ${k}`))
              ?.locale("en")
              ?.format("hh:mm")} ${moment(new Date(`01-01-2012, ${k}`))
                ?.locale("ar")
                ?.format("a")}`
        ),
      }));
    } else {
      let temp =
        b?.id == bookingDetails?.branch?.id ? bookingDetails?.time : [];
      if (temp?.length == 0) {
        temp?.push(i);
      } else if (temp?.length == 1 && temp?.includes(i)) {
        temp = [];
      } else if (
        temp?.length == 1 &&
        !temp?.includes(i) &&
        temp?.length != max
      ) {
        let k = moment(new Date(`01-01-2012, ${i}`))?.locale("en");
        let l = moment(new Date(`01-01-2012, ${temp[0]}`))?.locale("en");
        let total = timeSlot + buffer;

        let m = moment(
          new Date(`01-01-2012, ${temp[temp?.length - 1]}`)
        )?.locale("en");
        if (k < l) {
          while (true) {
            if (moment(k) < moment(l)) {
              temp.push(k?.format("hh:mm a"));
              k?.add(total, "minutes");
            } else break;
          }
        } else {
          while (true) {
            if (moment(m) < moment(k)) {
              m?.add(total, "minutes");
              temp.push(m?.format("hh:mm a"));
            } else break;
          }
        }
      } else {
        temp = [];
        temp?.push(i);
      }
      temp = temp?.sort(sortTime);
      setBookingDetails((s) => ({
        ...s,
        branch: temp?.length == 0 ? "" : b,
        time: temp,
        time_ar: temp?.map(
          (k) =>
            `${moment(new Date(`01-01-2012, ${k}`))
              ?.locale("en")
              ?.format("hh:mm")} ${moment(new Date(`01-01-2012, ${k}`))
                ?.locale("ar")
                ?.format("a")}`
        ),
      }));
    }
  };

  return (
    <>
      <div style={{ paddingBottom: 10 }}>
        <div
          className="checkout-step-one"
          style={{ paddingRight: 0, paddingLeft: 0 }}
        >
          <h1
            className="main-heading"
            style={{ paddingRight: 16, paddingLeft: 16 }}
          >
            {language == "ltr" ? "Reservations" : "التحفظات"}
          </h1>
          <div className="checkout-one">
            <div className="booking-single-div">
              <div className="booking-flex-div">
                <p className="booking-sub-title">
                  {language == "ltr" ? "Date" : "التاريخ"}
                </p>
                <p className="booking-info-text">
                  {language == "ltr" ? (
                    bookingDetails?.date?.format("D MMM YYYY")
                  ) : (
                    <>
                      <span style={{ fontSize: 18 }} className="number-span">
                        {bookingDetails?.date?.format("D")}
                      </span>{" "}
                      {moment(bookingDetails?.date)
                        ?.locale("ar")
                        ?.format("MMM")}{" "}
                      <span style={{ fontSize: 18 }} className="number-span">
                        {bookingDetails?.date?.format("YYYY")}
                      </span>
                    </>
                  )}
                </p>
              </div>
              <div className="pt-3">
                <p
                  className="booking-info-text open-calendar"
                  onClick={() => setOpenCal((a) => !a)}
                >
                  {!openCal
                    ? language == "ltr"
                      ? "Open Calendar"
                      : "افتح التقويم"
                    : language == "ltr"
                      ? "Close Calendar"
                      : "أغلق التقويم"}
                </p>
                <div
                  style={openCal ? {} : { display: "none" }}
                  className="booking-calendar-container"
                >
                  <Calendar
                    minDetail="year"
                    onChange={(e) => {
                      setBookingSets((a) => ({
                        ...a,
                        date: moment(e)?.locale("en"),
                      }));
                      setBookingDetails((a) => ({
                        ...a,
                        date: moment(e)?.locale("en"),
                      }));
                      setOpenCal((a) => !a);
                      setTabOpen((a) => 2);
                    }}
                    value={bookingDetails?.date?.toDate()}
                    minDate={bookingSets?.dateNow?.toDate()}
                  />
                </div>
                <div
                  style={!openCal ? {} : { display: "none" }}
                  className="date-scroller"
                >
                  {[...Array(2)].map((k, i) =>
                    moment(bookingSets?.date)
                      ?.startOf("day")
                      .locale("en")
                      .subtract(2 - i, "days") >=
                      moment(bookingSets?.dateNow)?.startOf("day") ? (
                      <span
                        onClick={() => {
                          setBookingDetails((book) => ({
                            ...book,
                            time: [],
                            time_ar: [],
                            branch: "",
                            date: moment(bookingSets?.date)
                              .locale("en")
                              .subtract(2 - i, "days"),
                          }));
                          setTabOpen((t) => 2);
                        }}
                        className={`date-selector ${moment(bookingSets?.date)
                          .locale("en")
                          .subtract(2 - i, "days")
                          ?.format("D MMM YYYY") ==
                          bookingDetails?.date?.format("D MMM YYYY")
                          ? "booking-slot-active"
                          : ""
                          }`}
                      >
                        <span
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <span>
                            {moment(bookingSets?.date)
                              .locale(language == "ltr" ? "en" : "ar")
                              .subtract(2 - i, "days")
                              ?.format("ddd")}
                          </span>

                          {language == "ltr" ? (
                            <span>
                              {moment(bookingSets?.date)
                                .locale("en")
                                .subtract(2 - i, "days")
                                ?.format("D MMM YYYY")}
                            </span>
                          ) : (
                            <span>
                              <span
                                style={{ fontSize: 13 }}
                                className="number-span"
                              >
                                {moment(bookingSets?.date)
                                  .locale("en")
                                  .subtract(2 - i, "days")
                                  ?.format("D")}
                              </span>{" "}
                              {moment(bookingSets?.date)
                                ?.locale("ar")
                                ?.subtract(2 - i, "days")
                                ?.format("MMM")}{" "}
                              <span
                                style={{ fontSize: 13 }}
                                className="number-span"
                              >
                                {moment(bookingSets?.date)
                                  .locale("en")
                                  .subtract(2 - i, "days")
                                  ?.format("YYYY")}
                              </span>
                            </span>
                          )}
                        </span>
                      </span>
                    ) : null
                  )}
                  {[...Array(30)].map((k, i) => (
                    <span
                      onClick={() => {
                        setBookingDetails((book) => ({
                          ...book,
                          time: [],
                          time_ar: [],
                          branch: "",
                          date: moment(bookingSets?.date)
                            .locale("en")
                            .add(i, "days"),
                        }));
                        setTabOpen((t) => 2);
                      }}
                      className={`date-selector ${moment(bookingSets?.date)
                        .locale("en")
                        .add(i, "days")
                        ?.format("D MMM YYYY") ==
                        bookingDetails?.date?.format("D MMM YYYY")
                        ? "booking-slot-active"
                        : ""
                        }`}
                    >
                      <span
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          {moment(bookingSets?.date)
                            .locale(language == "ltr" ? "en" : "ar")
                            .add(i, "days")
                            ?.format("ddd")}
                        </span>

                        {language == "ltr" ? (
                          <span>
                            {moment(bookingSets?.date)
                              .locale("en")
                              .add(i, "days")
                              ?.format("D MMM YYYY")}
                          </span>
                        ) : (
                          <span>
                            <span
                              style={{ fontSize: 13 }}
                              className="number-span"
                            >
                              {moment(bookingSets?.date)
                                .locale("en")
                                .add(i, "days")
                                ?.format("D")}
                            </span>{" "}
                            {moment(bookingSets?.date)
                              ?.locale("ar")
                              ?.add(i, "days")
                              ?.format("MMM")}{" "}
                            <span
                              style={{ fontSize: 13 }}
                              className="number-span"
                            >
                              {moment(bookingSets?.date)
                                .locale("en")
                                .add(i, "days")
                                ?.format("YYYY")}
                            </span>
                          </span>
                        )}
                      </span>
                    </span>
                  ))}
                </div>
              </div>
            </div>
            <div className="booking-single-div">
              <div className="booking-flex-div">
                <p className="booking-sub-title">
                  {language == "ltr" ? "Branch" : "أفرعنا"}
                </p>
                <p className="booking-info-text">
                  {bookingDetails?.branch == ""
                    ? language == "ltr"
                      ? "All"
                      : "الجميع"
                    : language == "ltr"
                      ? bookingDetails?.branch?.name
                      : bookingDetails?.branch?.arabic_name}
                </p>
              </div>
              <div
                className="pt-3"
                style={!openCal && tabOpen >= 2 ? {} : { display: "none" }}
              >
                <ul
                  style={tabOpen >= 2 ? {} : { display: "none" }}
                  className="booking-branch-list"
                >
                  {bookingSets?.branches?.map((k) => (
                    <li className="booking-branch">
                      <img className="booking-branch-img" src={k?.image}></img>
                      <div className="booking-branch-details">
                        <div className="booking-branch-title">
                          {language == "ltr" ? k?.name : k?.arabic_name}
                        </div>
                        <div className="booking-branch-desc">
                          {language == "ltr"
                            ? k?.description
                            : k?.arabic_description}
                        </div>
                        <div
                          className="booking-time-slot-div"
                          style={{
                            justifyContent: "flex-start",
                            gap: 10,
                            gridTemplateColumns:
                              "repeat(auto-fill, minmax(0px, 140px))",
                          }}
                        >
                          {k?.timeSlots?.map((i) => (
                            <button
                              onClick={() => {

                                if (
                                  !k?.booked_time_slot?.includes(
                                    moment(new Date(`01-01-2012, ${i}`))
                                      ?.locale("en")
                                      ?.format("HH:mm:ss")
                                  )
                                )
                                  if (
                                    bookingDetails?.time?.length != 1 ||
                                    bookingDetails?.time?.includes(i) ||
                                    k?.cont ||
                                    bookingDetails?.branch?.id != k?.id ||
                                    bookingDetails?.time?.length == k?.max
                                  )
                                    onTimeClick(
                                      i,
                                      k?.cont,
                                      k?.max,
                                      k?.slot_time,
                                      k?.buffer_time,
                                      k
                                    );
                                  else {
                                    if (contUnavail?.includes(i))
                                      onTimeClick(
                                        i,
                                        k?.cont,
                                        k?.max,
                                        k?.slot_time,
                                        k?.buffer_time,
                                        k
                                      );
                                  }
                              }}
                              className={`booking-time-slot ${bookingDetails?.time?.includes(i) &&
                                bookingDetails?.branch?.id == k?.id
                                ? "booking-slot-active"
                                : ""
                                } ${k?.booked_time_slot?.includes(
                                  moment(new Date(`01-01-2012, ${i}`))
                                    ?.locale("en")
                                    ?.format("HH:mm:ss")
                                ) ||
                                  (bookingDetails?.time?.length == 1 &&
                                    !bookingDetails?.time?.includes(i) &&
                                    !contUnavail?.includes(i) &&
                                    !k?.cont &&
                                    bookingDetails?.branch?.id == k?.id &&
                                    bookingDetails?.time?.length != k?.max) ||
                                  (k?.cont &&
                                    !bookingDetails?.time?.includes(i) &&
                                    bookingDetails?.branch?.id == k?.id &&
                                    bookingDetails?.time?.length == k?.max)
                                  ? "booking-time-booked"
                                  : ""
                                }`}
                            >
                              {language == "ltr" ? (
                                `${i} -
                                ${moment(new Date(`01-01-2012, ${i}`))
                                  ?.locale("en")
                                  ?.add(k?.slot_time, "minutes")
                                  ?.format("hh:mm a")}`
                              ) : (
                                <>
                                  <span
                                    style={{ fontSize: 12 }}
                                    className="number-span"
                                  >
                                    {moment(new Date(`01-01-2012, ${i}`))
                                      ?.locale("en")
                                      ?.format("hh:mm")}
                                  </span>{" "}
                                  {moment(new Date(`01-01-2012, ${i}`))
                                    ?.locale("ar")
                                    ?.format("a")}{" "}
                                  -{" "}
                                  <span
                                    style={{ fontSize: 12 }}
                                    className="number-span"
                                  >
                                    {moment(new Date(`01-01-2012, ${i}`))
                                      ?.locale("en")
                                      .add(k?.slot_time, "minutes")
                                      ?.format("hh:mm")}
                                  </span>{" "}
                                  {moment(new Date(`01-01-2012, ${i}`))
                                    ?.locale("ar")
                                    .add(k?.slot_time, "minutes")
                                    ?.format("a")}
                                </>
                              )}
                            </button>
                          ))}
                        </div>

                      </div>
                    </li>
                  ))}
                </ul>

              </div>
            </div>
            {!(bookingSets?.maxAttend == 0 || bookingSets?.maxAttend == "") && (
              <div className="booking-single-div">
                <div
                  className="booking-flex-div"
                  onClick={() => (tabOpen == 2 ? setTabOpen((t) => 4) : null)}
                >
                  <p className="booking-sub-title">
                    {language == "ltr" ? "Party Of" : "عدد الحضور"}
                  </p>
                  <p
                    style={{ fontSize: 18 }}
                    className="booking-info-text number-span"
                  >
                    {bookingDetails?.attendee}
                  </p>
                </div>
                <div
                  className="pt-4"
                  style={!openCal && tabOpen == 4 ? {} : { display: "none" }}
                >
                  <div style={{ padding: "0 20px" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div className="booking-quantity-control">
                        <button
                          className={`control-button ${bookingDetails?.attendee == 1 ? "diabled-book" : ""
                            }`}
                          onClick={onMinus}
                        >
                          <i className="fa fa-minus"></i>
                        </button>
                        <p
                          style={{ fontSize: 20 }}
                          className="quantity-text number-span"
                        >
                          {bookingDetails?.attendee}
                        </p>
                        <button
                          className={`control-button ${bookingDetails?.attendee == bookingSets?.maxAttend
                            ? "diabled-book"
                            : ""
                            }`}
                          onClick={onPlus}
                        >
                          <i className="fa fa-plus"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className="bottom-button iphone-issue"
        style={{ position: "absolute", width: "100%" }}
      >
        <Link
          onClick={(e) => {
            e.preventDefault();
            history.goBack();
          }}
          className="text-center checkout-button checkout-button-back"
        >
          {language == "ltr" ? "Back" : "تراجع"}
        </Link>
        <Link
          onClick={(e) => {
            e.preventDefault();
            if (checkNext()) setStepper((stepper) => stepper + 1);
          }}
          className="text-center checkout-button checkout-button-next"
        >
          {checkNext()
            ? language == "ltr"
              ? "Next"
              : "متابعة"
            : language == "ltr"
              ? "Select all the fields"
              : "حدد جميع الحقول"}
        </Link>
      </div>
    </>
  );
}

export default BranchBooking;
