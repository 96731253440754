import React, { useContext, useEffect, useState } from "react";
import $ from "jquery";
import ToggleCategoryProduct from "../../ToggleCategoryProduct";
import { LanguageContext } from "../../../App";
import SubCategoriesSideScroll from "../../SubCategories/SubCategoriesSideScroll";

function ProductBoxView({
  selectcategorynow,
  toggleView,
  indexId,
  categories,
  lastBookElementRef,
  setActiveCategory,
  setcategoryopen,
  userClick,
}) {
  const [loading, setLoading] = useState(false);
  const [isScroll, setisScroll] = useState(false);
  const { language } = useContext(LanguageContext);

  useEffect(() => {
    if (
      toggleView == 1 &&
      categories &&
      selectcategorynow != categories[0].category_id
    ) {
      // if (toggleView == 1 && categories && selectcategorynow != null) {
      let setFirst = false;
      categories.map((element, i) => {
        const activediv = document
          .getElementById(`category${i}`)
          .getBoundingClientRect();
        if (activediv.top > 59) {
          setFirst = true;
        } else {
          setFirst = false;
        }
      });
      if (setFirst) {
        setActiveCategory(categories[0].category_id);
      }
    }
  }, []);

  useEffect(() => {
    if (selectcategorynow !== null && toggleView == 1) {
      $("html, body").animate(
        {
          scrollTop:
            $(`#category${indexId}`).offset().top -
            (window.screen.width < 991 ? 107 : 59),
        },
        "3000"
      );
    }
  }, [indexId]);
  useEffect(() => {
    if (toggleView == 1 && categories && !userClick) {
      categories.map((element, i) => {
        const activediv = document
          .getElementById(`category${i}`)
          .getBoundingClientRect();
        if (activediv.top > 0 && activediv.top < 59) {
          setActiveCategory(element.category_id);
        }
      });
    }
  }, [isScroll]);
  window.onscroll = (e) => {
    setisScroll((prev) => !prev);
  };
  return (
    <div className="back-grey" id="categoryflex">
      {categories.map((element, i) => (
        <div className="category-container toggle-productList-div">
          <div
            className="toggle-product-category-name"
            onClick={() => setcategoryopen(true)}
            id={`category${i}`}
          >
            {language == "ltr"
              ? element?.category_name
              : element?.category_name_ar}
          </div>
          <div className="container">
            <div className="row">
              {loading == false
                ? element.products.map((item, k) => {
                    if (k == element.products.length - 1 && lastBookElementRef)
                      return (
                        <li
                          ref={lastBookElementRef}
                          className="col-lg-6 col-md-4 col-6 mt-3 category-tab mb-3 product-category-pad"
                        >
                          {element.is_subcategory ? (
                            <SubCategoriesSideScroll item={item} />
                          ) : (
                            <ToggleCategoryProduct
                              item={item}
                            ></ToggleCategoryProduct>
                          )}
                        </li>
                      );
                    else
                      return (
                        <li className="col-lg-6 col-md-4 col-6 mt-3 category-tab mb-3 product-category-pad">
                          {element.is_subcategory ? (
                            <SubCategoriesSideScroll item={item} />
                          ) : (
                            <ToggleCategoryProduct
                              item={item}
                            ></ToggleCategoryProduct>
                          )}
                        </li>
                      );
                  })
                : [...Array(7)].map((k, i) => (
                    <li className="product-category-pad shine-design">
                      <div className="product-category-item">
                        <a style={{ alignItems: "center" }}>
                          <div
                            className="product-img-div"
                            style={{
                              position: "static",
                              backgroundColor: "#f3f3f3",
                              width: "90%",
                              zIndex: 0,
                            }}
                          ></div>
                          <div
                            className="mt-3"
                            style={{
                              minHeight: "44px",
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                            }}
                          >
                            <span
                              style={{
                                backgroundColor: "#f3f3f3",
                                display: "inline-block",
                                width: "50%",
                                height: "21px",
                                marginBottom: 10,
                              }}
                            ></span>
                            <span
                              style={{
                                backgroundColor: "#f3f3f3",
                                display: "inline-block",
                                width: "90%",
                                height: "15px",
                                marginBottom: 5,
                              }}
                            ></span>
                            <span
                              style={{
                                backgroundColor: "#f3f3f3",
                                display: "inline-block",
                                width: "80%",
                                height: "15px",
                                marginBottom: 15,
                              }}
                            ></span>
                          </div>
                          <div
                            className="product-price-div"
                            style={{ alignItems: "center" }}
                          >
                            <div className="product-price">
                              <span
                                style={{
                                  backgroundColor: "#f3f3f3",
                                  height: "20px",
                                  width: "70px",
                                }}
                              ></span>
                            </div>
                            <div
                              style={{
                                borderRadius: "50%",
                                width: "25px",
                                backgroundColor: "#f3f3f3",
                                minHeight: "25px",
                                margin: "0 6px",
                              }}
                            ></div>
                          </div>
                        </a>
                      </div>
                    </li>
                  ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ProductBoxView;
