import React from 'react'
import DetailsCommon from './DetailsCommon'

const AccordianContactStore = ({ vendorData }) => {
    const vendor_Data = [
        { english_value: "Phone Number", arabic_value: "رقم الهاتف", value: vendorData?.phone, is_phone: true },
        { english_value: "Email Address", arabic_value: "عنوان البريد الإلكتروني", value: vendorData?.support_mail },
    ]
    return (
        <div>
            {vendor_Data && vendor_Data.map((row, i) =>
                <DetailsCommon key={i} data={row} />
            )
            }
        </div>
    )
}

export default AccordianContactStore