import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../App";
import ProductsList from "./Products/components/ProductsList";
import SubCategoriesList from "./SubCategories/SubCategoriesList";

function CategoryListTab({ category, idx, setcategoryopen }) {
  const [loading, setLoading] = useState(false);
  const { language } = useContext(LanguageContext);

  return (
    <div className="category-accordion-div" id={`category${idx}`}>
      <button
        onClick={() => setcategoryopen(true)}
        className={`category-accordion  list-accordion`}
      >
        {language === "ltr"
          ? category?.category_name
          : category?.category_name_ar}
      </button>
      <div className={`area-list-collapse`}>
        {category?.is_subcategory ? (
          <SubCategoriesList categories={category?.products} />
        ) : (
          <ProductsList
            products={category?.products}
            loading={loading}
          ></ProductsList>
        )}
      </div>
    </div>
  );
}

export default CategoryListTab;
