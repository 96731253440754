import React, { useContext, useEffect, useState } from "react";
import { LanguageContext, VendorContext } from "../../App";

function AppInstall({
  installable,
  setInstallable,
  handleInstallClick,
}) {
  const details = useContext(VendorContext);
  const { language } = useContext(LanguageContext);
  const [showAnimation, setShowAnimation] = useState(true)
  useEffect(() => {
    setTimeout(() => {
      setTimeout(() => {
        setInstallable(false)
      }, 500);
      setShowAnimation(false)
    }, 4000);
  }, [])
  const handleScroll = () => {
    if (window.scrollY - 360 > (100 * 80) / window.innerHeight) {
      setTimeout(() => {
        setInstallable(false)
      }, 500);
      setShowAnimation(false)
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [])


  return installable ? <div className={showAnimation ? "showAppInstall" : "hideAppInstall"} style={{ width: "100%" }} >
    <div
      style={{
        padding: "10px",
        height: 55,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        fontSize: 14,
        margin: "0 auto",
        backgroundColor: details?.vendor?.vendor_color,
      }}
    >

      <p
        style={{
          color: "white",
          width:
            navigator.userAgent.indexOf("Safari") > -1
              ? navigator.userAgent.indexOf("Chrome") > -1
                ? "70%"
                : "90%"
              : "70%",
          fontSize: "13px",
          fontWeight: "600",
        }}
      >
        {language == "ltr" ? (
          navigator.userAgent.indexOf("Safari") > -1 ? (
            navigator.userAgent.indexOf("Chrome") > -1 ? (
              "Add our app for a great experience"
            ) : (
              <>
                Use our App by clicking on{" "}
                <span style={{ fontWeight: 700 }}>
                  "Share"
                  <img
                    style={{ width: 24 }}
                    src="pictures/imageedit_2_8805501669.png"
                  ></img>
                </span>
                button, then{" "}
                <span style={{ fontWeight: 700 }}>
                  "Add to Home Screen"
                </span>
              </>
            )
          ) : (
            "Add our app for a great experience"
          )
        ) : navigator.userAgent.indexOf("Safari") > -1 ? (
          navigator.userAgent.indexOf("Chrome") > -1 ? (
            "استمتع بتجربة تطبيقنا للطلب بشكل اسهل"
          ) : (
            <>
              جرب تطبيقنا من خلال الضغط على زر{" "}
              <span style={{ fontWeight: 700 }}>
                "المشاركة"
                <img
                  style={{ width: 24 }}
                  src="pictures/imageedit_2_8805501669.png"
                ></img>
              </span>
              ثم اختر{" "}
              <span style={{ fontWeight: 700 }}>
                "إضافة الى الشاشة الرئيسية"
              </span>
            </>
          )
        ) : (
          "استمتع بتجربة تطبيقنا للطلب بشكل اسهل"
        )}
      </p>
      <button
        onClick={() => setInstallable(false)}
        style={{
          padding: "3px 6px",
          border: "none",
          fontSize: "20px",
          borderRadius: "50%",
          fontWeight: 800,
          cursor: "pointer",
          color: "white",
          backgroundColor: details?.vendor?.vendor_color,
        }}
      >
        <i className="fa fa-times" aria-hidden="true"></i>
      </button>
      {navigator.userAgent.indexOf("Safari") > -1 ? (
        navigator.userAgent.indexOf("Chrome") > -1 ? (
          <button
            onClick={() => handleInstallClick()}
            style={{
              padding: "5px 10px",
              fontWeight: 600,
              borderRadius: "30px",
              color: details?.vendor?.vendor_color,
              cursor: "pointer",
              border: "none",
              backgroundColor: "white",
            }}
          >
            {language == "ltr" ? "Install" : "تثبيت"}
          </button>
        ) : null
      ) : (
        <button
          onClick={() => handleInstallClick()}
          style={{
            padding: "5px 10px",
            fontWeight: 600,
            borderRadius: "30px",
            color: details?.vendor?.vendor_color,
            cursor: "pointer",
            border: "none",
            backgroundColor: "white",
          }}
        >
          {language == "ltr" ? "Install" : "تثبيت"}
        </button>
      )}
    </div>
  </div> : null
}

export default AppInstall;
