import React, { useContext, useEffect, useState } from "react";
import { CartContext } from "../../../App";
import CategoryDropList from "./CategoryDropList";

function CategoryDrop({ setcategoryopen, categories, categorynow }) {
  const [categoryActive, setCategoryActive] = useState(null);
  const { cart } = useContext(CartContext);

  useEffect(() => {
    if (categories?.length) {
      let temp = categories?.map((k, i) => {
        return { [i]: true };
      });
      setCategoryActive(temp);
    }
  }, [categories]);
  return (
    <div className={cart?.cartCount ? "bottom-category-accordian" : ""}>
      {categories?.map((category, i) => (
        <CategoryDropList
          category={category}
          idx={i}
          key={i}
          categoryActive={categoryActive}
          setcategoryopen={setcategoryopen}
          setCategoryActive={setCategoryActive}
          categorynow={categorynow}
        ></CategoryDropList>
      ))}
    </div>
  );
}

export default CategoryDrop;
