import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

function ProductCarousel({
  product,
  addedVariaton,
  productvariationPrice,
}) {
  return product?.image ? (
    <OwlCarousel
      dir="ltr"
      items={1}
      key={
        productvariationPrice?.[addedVariaton.toString()]?.image
          ? productvariationPrice?.[addedVariaton.toString()]?.image
          : product?.image
      }
      className="owl-theme"
      loop
      autoplay={
        product?.product_images?.length != 0 &&
        !productvariationPrice?.[addedVariaton.toString()]?.image
      }
      mouseDrag={
        product?.product_images?.length != 0 &&
        !productvariationPrice?.[addedVariaton.toString()]?.image
      }
      dots={false}
      animateIn="fadeIn"
      animateOut="fadeOut"
    >
      <div className="product-owl-img">
        <img
          className="img"
          src={`${productvariationPrice?.[addedVariaton.toString()]?.image
            ? productvariationPrice?.[addedVariaton.toString()]?.image
            : product?.image
            }`}
        />
      </div>

      {product?.product_images?.length != 0 &&
        !productvariationPrice?.[addedVariaton.toString()]?.image
        ? product?.product_images?.map((i, k) => (
          <div className="product-owl-img">
            <img className="img" src={`${i}`} />
          </div>
        ))
        : null}
    </OwlCarousel>
  ) : null;
}

export default ProductCarousel;
